/**
 * The navbar container
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import avatar from "../../assets/images/avatar-placeholder.png";
import avatarRetina from "../../assets/images/avatar-placeholder@2x.png";
import Can from "../Can";
import {
  Badge,
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import accountActions from "../../redux/account";
import itemsActions from "../../redux/items";
import { push } from "connected-react-router";
import { setLanguage } from "redux-i18n";
import ItemPreview from "../ItemPreview";
import { isServer } from "../../utils/store";
/**
 * Selects props from the store and passes them to the component
 * @param {Object} state the store
 */
const mapStateToProps = (state) => ({
  searchResults: state.items.search_results,
  lang: state.i18nState.lang,
  currentUser: state.account.currentUser,
  signedin: state.account.signedin,
});
/**
 * Binds dispatch method to the actions passed as props to the component
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setLanguage: setLanguage,
      logout: accountActions.logout,
      searchItems: itemsActions.search,
      navigateTo: push,
    },
    dispatch
  );

/**
 * List of languages supported
 */
const languages = {
  it: "Italiano",
  en: "English",
  de: "Deutsch",
};
/**
 * The navigation bar container. Renders the top bar with links to navigte in the site
 */
export class Navigationbar extends Component {
  /**
   * @constructor binds the toggle method and sets an isOpen boolean in the state
   * @param {} props
   */
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.closeSearch = this.closeSearch.bind(this);
    this.openSearch = this.openSearch.bind(this);
    this.state = {
      isOpen: false,
      searchActive: false,
    };
    this.debounceTimeout = null; // Added debounceTimeout
  }

  /** toggles the open state of the navbar */
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  /** toggles the open state of the navbar */
  openSearch() {
    this.setState({
      searchActive: true,
    });
  }

  /** toggles the open state of the navbar */
  connectWithBox() {
    window.open(
      "https://app.box.com/api/oauth2/authorize?response_type=code&client_id=so5g6esao0td58ed7zg5pm1un0hzoj1i&state=user%3D" +
        this.props.currentUser.email +
        "%26id=" +
        this.props.currentUser.id
    );
  }

  /** toggles the open state of the navbar */
  closeSearch() {
    setTimeout(() => {
      this.setState({
        searchActive: false,
      });
    }, 250);
  }

  /** handles all navigation links */
  handleNavigationAction = (path, event) => {
    event.preventDefault();
    this.props.navigateTo(path);
  };

  /** handles the click on logout */
  handleLogoutAction = (event) => {
    event.preventDefault();
    const { currentUser } = this.props;
    this.props.logout(currentUser.tokens);
  };

  /** handles the change language event */
  handleI18nAction = (lang, event) => {
    event.preventDefault();
    this.props.setLanguage(lang);
  };

  performItemSearch = (ev) => {
    const { searchItems } = this.props;
    clearTimeout(this.debounceTimeout); // Clear previous timeout
    const searchValue = ev.target.value;
    if (searchValue.length > 3) {
      this.debounceTimeout = setTimeout(() => {
        searchItems({ q: searchValue });
      }, 500); // Delay search for 0.5 seconds
    }
  };
  /** renders the user avatar in the navbar */

  renderAvatar() {
    const { signedin, currentUser } = this.props;
    var avatarSource = avatar;
    var avatarRetinaSource = avatarRetina;
    var alt = "profile img placeholder";
    if (signedin && currentUser && currentUser.avatar) {
      alt = currentUser.name;
      if (currentUser.avatar !== "/images/avatar-placeholder.png") {
        avatarSource = currentUser.avatar;
        avatarRetinaSource = currentUser.avatar;
      }
    }
    return (
      <img
        src={avatarSource}
        width="24"
        height="24"
        data-src-retina={avatarRetinaSource}
        alt={alt}
        className="rounded-circle"
      />
    );
  }

  renderLanguageDropdown(showText) {
    return (
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret>
          <i className="fa fa-language"></i>&nbsp;{showText && "Language"}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem>{languages[this.props.lang]}</DropdownItem>
          {Object.keys(languages).map((language) => {
            if (language !== this.props.lang) {
              return (
                <DropdownItem
                  key={language}
                  onClick={this.handleI18nAction.bind(this, language)}
                >
                  {languages[language]}
                </DropdownItem>
              );
            } else return "";
          })}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }

  renderSearchResult(item) {
    const { navBarSearchSettings } = this.props;
    let linkType = "production";
    if (navBarSearchSettings && navBarSearchSettings.can_view_play_mode) {
      if (navBarSearchSettings.location.includes("/design/"))
        linkType = "design";
      if (!navBarSearchSettings.can_view_production_mode) linkType = "design";
    }
    if (navBarSearchSettings && navBarSearchSettings.can_view_status_mode) {
      if (navBarSearchSettings.location.includes("/orderitem/"))
        linkType = "orderitem";
      if (!navBarSearchSettings.can_view_production_mode)
        linkType = "orderitem";
    }
    return (
      <Link
        to={`/${linkType}/${item.id}`}
        key={item.id + "-PreviewListLink"}
        className="itempreview"
      >
        <ItemPreview item={item} key={item.id + "-ItemPreview"} />
      </Link>
    );
  }

  render() {
    const { signedin, searchResults, currentUser } = this.props;

    return (
      <div
        style={this.props.style}
        className={classNames("navigationbar ", {
          "navigationbar--sticky": this.props.isSticky,
        })}
      >
        <Navbar light color="light" expand="sm" className="shadow">
          <NavbarToggler aria-label="navigation_toggle" onClick={this.toggle} />
          {signedin && (
            <Can user={currentUser} authorize={{ use_search_bar: "Item" }}>
              <div className="navigationbar__search">
                <Input
                  onFocus={this.openSearch}
                  onBlur={this.closeSearch}
                  type="text"
                  name="search"
                  id="search-input"
                  placeholder="search transaction, Invoices or help"
                  onChange={this.performItemSearch.bind(this)}
                />
                {this.state.searchActive &&
                  searchResults &&
                  searchResults.length > 0 && (
                    <div
                      className="container shadow white-bg"
                      style={{
                        width: "600px",
                        maxHeight: "600px",
                        overflow: "scroll",
                        position: "absolute",
                        zIndex: 9999,
                        top: "50px",
                      }}
                    >
                      <Nav vertical>
                        {searchResults &&
                          searchResults.length !== 0 &&
                          searchResults.map((item) => (
                            <NavItem key={item.id + "-PreviewList"}>
                              {this.renderSearchResult(item)}
                            </NavItem>
                          ))}
                        {this.state.searchActive &&
                          searchResults &&
                          searchResults.length === 0 && (
                            <em>{this.context.t("No Items found")}</em>
                          )}
                      </Nav>
                    </div>
                  )}
              </div>
            </Can>
          )}
          <Nav navbar className="navigationbar__user">
            {!signedin && this.renderLanguageDropdown()}
            {!signedin && (
              <NavItem>
                <NavLink href="/account/login">
                  <i className="fa fa-user"></i>&nbsp;Login
                </NavLink>
              </NavItem>
            )}

            {signedin && (
              <UncontrolledDropdown>
                <DropdownToggle nav>
                  {signedin && (
                    <span className="d-none d-lg-inline-block d-xl-inline-block">
                      {this.props.currentUser.name ||
                        this.props.currentUser.email}
                    </span>
                  )}
                  &nbsp;
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                  &nbsp;
                  {this.renderAvatar()}
                </DropdownToggle>
                <DropdownMenu right>
                  {this.renderLanguageDropdown(true)}

                  {false && signedin && !isServer && (
                    <NavItem>
                      <NavLink
                        href="#"
                        onClick={this.connectWithBox.bind(this)}
                      >
                        BOX Connect ({this.props.currentUser.box_status})
                      </NavLink>
                    </NavItem>
                  )}
                  {signedin && (
                    <NavItem>
                      <NavLink
                        href="#"
                        onClick={this.handleLogoutAction.bind(this)}
                      >
                        <i className="fa fa-sign-out"></i>&nbsp;Logout
                      </NavLink>
                    </NavItem>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
            )}
          </Nav>
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavLink
                href="#"
                onClick={this.handleNavigationAction.bind(this, "/")}
              >
                <i className="fa fa-home"></i>
              </NavLink>
              {signedin && (
                <Can
                  user={currentUser}
                  authorize={{ view_production_mode: "Item" }}
                >
                  <NavLink
                    href="#"
                    onClick={this.handleNavigationAction.bind(
                      this,
                      "/production/"
                    )}
                  >
                    <i className="fa fa-list"></i>
                  </NavLink>
                </Can>
              )}
              {signedin && (
                <Can user={currentUser} authorize={{ view_play_mode: "Item" }}>
                  <NavLink
                    href="#"
                    onClick={this.handleNavigationAction.bind(this, "/design/")}
                  >
                    <i className="fa fa-paint-brush"></i>
                  </NavLink>
                </Can>
              )}
              {signedin && (
                <Can user={currentUser} authorize={{ view_play_mode: "Item" }}>
                  <NavLink
                    href="#"
                    onClick={this.handleNavigationAction.bind(
                      this,
                      "/premade_files/"
                    )}
                  >
                    <i className="fa fa-folder"></i>
                  </NavLink>
                </Can>
              )}
              {signedin && (
                <Can
                  user={currentUser}
                  authorize={{ view_status_mode: "Item" }}
                >
                  <NavLink
                    href="#"
                    onClick={this.handleNavigationAction.bind(
                      this,
                      "/orderitem/"
                    )}
                  >
                    <i className="fa fa-question-circle"></i>
                  </NavLink>
                </Can>
              )}
              {signedin && (
                <Can
                  user={currentUser}
                  authorize={{ show: "item_status_statistic" }}
                >
                  <NavLink
                    href="#"
                    onClick={this.handleNavigationAction.bind(
                      this,
                      "/statistics/"
                    )}
                  >
                    <i className="fa fa-bar-chart"></i>
                  </NavLink>
                </Can>
              )}
              {signedin && (
                <Can
                  user={currentUser}
                  authorize={{ show: "item_status_statistic" }}
                >
                  <NavLink
                    href="#"
                    onClick={this.handleNavigationAction.bind(
                      this,
                      "/dashboard/"
                    )}
                  >
                    <i className="fa fa-truck"></i>
                  </NavLink>
                </Can>
              )}
              {signedin && false && (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav>
                    <i className="fa fa-bell"></i>
                    <Badge color="danger" pill>
                      1
                    </Badge>
                  </DropdownToggle>
                  <DropdownMenu>
                    {this.context.t("Some notifications")}
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}

              {signedin && false && (
                <NavLink
                  href="#"
                  onClick={this.handleNavigationAction.bind(this, "/urgent/")}
                >
                  <i className="fa fa-exclamation-triangle"></i>
                </NavLink>
              )}
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

Navigationbar.propTypes = {
  style: PropTypes.object,
  isSticky: PropTypes.bool,
  searchResults: PropTypes.array,
  lang: PropTypes.string,
  currentUser: PropTypes.object,
  signedin: PropTypes.bool,
  setLanguage: PropTypes.func,
  logout: PropTypes.func,
  searchItems: PropTypes.func,
  navigateTo: PropTypes.func,
};

Navigationbar.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigationbar);
