import { createAction } from 'redux-actions';
import notificationsActions from '../notifications'
/**
 * The list of action types for CRUD operations on the resource
 */
const LIST = 'materials/LIST';
const LIST_SUCCESS = 'materials/LIST_SUCCESS';
const LIST_ALL_SUCCESS = 'materials/LIST_ALL_SUCCESS';
const LIST_FAIL = 'materials/LIST_FAIL';
const SHOW = 'materials/SHOW';
const SHOW_SUCCESS = 'materials/SHOW_SUCCESS';
const SHOW_FAIL = 'materials/SHOW_FAIL';
const CREATE = 'materials/CREATE';
const CREATE_SUCCESS = 'materials/CREATE_SUCCESS';
const CREATE_FAIL = 'materials/CREATE_FAIL';
const UPDATE = 'materials/UPDATE';
const UPDATE_SUCCESS = 'materials/UPDATE_SUCCESS';
const UPDATE_FAIL = 'materials/UPDATE_FAIL';
const REMOVE = 'materials/REMOVE';
const REMOVE_SUCCESS = 'materials/REMOVE_SUCCESS';
const REMOVE_FAIL = 'materials/REMOVE_FAIL';
const STORE = 'materials/STORE';
const UNSTORE = 'materials/UNSTORE';

/**
 * list materials actions
 */
const listSuccess = createAction(LIST_SUCCESS);
const listAllSuccess = createAction(LIST_ALL_SUCCESS);
const listFail = createAction(LIST_FAIL);
const list = createAction(LIST);

/**
 * show material actions
 */
const showSuccess = createAction(SHOW_SUCCESS);
const showFail = createAction(SHOW_FAIL);
const show = createAction(SHOW);

/**
 * create material actions
 */
const createSuccess = createAction(CREATE_SUCCESS);
const createFail = createAction(CREATE_FAIL);
const create = createAction(CREATE);

/**
 * update material actions
 */
const updateSuccess = createAction(UPDATE_SUCCESS);
const updateFail = createAction(UPDATE_FAIL);
const update = createAction(UPDATE);

/**
 * remove material actions
 */
const removeSuccess = createAction(REMOVE_SUCCESS);
const removeFail = createAction(REMOVE_FAIL);
const remove = createAction(REMOVE);

/**
 * store materials actions
 * it also dispatches a notification with the amount of storage used
 */
const storeMaterial = createAction(STORE,
  () => async (dispatch) => {
    if (navigator && 'storage' in navigator && 'estimate' in navigator.storage) {
      navigator.storage.estimate()
      .then(function(estimate){
        const {usage, quota} = estimate
        const percentUsed = Math.round(usage / quota * 100);
        const usageInMib = Math.round(usage / (1024 * 1024));
        const quotaInMib = Math.round(quota / (1024 * 1024));
        const message = `${usageInMib} out of ${quotaInMib} MiB used (${percentUsed}%)`;
        const color = percentUsed > 80 ? 'danger' : 'success'
        let alertOptions = { message, color, origin: 'storage', timeout: 5 }
        dispatch(notificationsActions.showAlert(alertOptions))
      });
    }
  }
);
const unstoreMaterial = createAction(UNSTORE);


/**
 * exported actions
 */
export default {
  list: list,
  listSuccess: listSuccess,
  listAllSuccess: listAllSuccess,
  listFail: listFail,
  show: show,
  showSuccess: showSuccess,
  showFail: showFail,
  create: create,
  createSuccess: createSuccess,
  createFail: createFail,
  update: update,
  updateSuccess: updateSuccess,
  updateFail: updateFail,
  remove: remove,
  removeSuccess: removeSuccess,
  removeFail: removeFail,
  storeMaterial: storeMaterial,
  unstoreMaterial: unstoreMaterial
}
