/** 
 * The Root container
*/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom'
import PrivateRoute from '../components/PrivateRoute'
import { StickyContainer, Sticky } from 'react-sticky';
import Navbar from '../components/Navbar'
import FlashAlert from '../components/FlashAlert';
import OfflineBar from '../components/OfflineBar'
import Loadable from 'react-loadable';
import { hasPermission } from '../components/Can';
/**
 * Loadable import for Home container
 */
const Home = Loadable({
  loader: () => import(/* webpackChunkName: "Home" */ './Home'),
  loading: () => null,
  modules: ['Home']
});

/**
 * Loadable import for About container
 */
const About = Loadable({
  loader: () => import(/* webpackChunkName: "About" */ '../components/About'),
  loading: () => null,
  modules: ['About']
});

/**
 * Loadable import for Account container
 */
const Account = Loadable({
  loader: () => import(/* webpackChunkName: "Account" */ './Account'),
  loading: () => null,
  modules: ['Account']
});


/**
 * Loadable import for Dashboard container
 */
const StatisticDashboard = Loadable({
  loader: () => import(/* webpackChunkName: "Dashboard" */ './StatisticDashboard'),
  loading: () => null,
  modules: ['StatisticDashboard']
});

/**
 * Loadable import for Dashboard container
 */
const ShippingStatisticDashboard = Loadable({
  loader: () => import(/* webpackChunkName: "Dashboard" */ './ShippingStatisticDashboard'),
  loading: () => null,
  modules: ['ShippingStatisticDashboard']
});

/**
 * Loadable import for Items container
 */
const Items = Loadable({
  loader: () => import(/* webpackChunkName: "Items" */ './Items'),
  loading: () => null,
  modules: ['Items']
});

/**
 * Loadable import for Items container
 */
const ProductionItems = Loadable({
  loader: () => import(/* webpackChunkName: "ProductionItems" */ './ProductionItems'),
  loading: () => null,
  modules: ['ProductionItems']
});

/**
 * Loadable import for Items container
 */
const DesignItems = Loadable({
  loader: () => import(/* webpackChunkName: "DesignItems" */ './DesignItems'),
  loading: () => null,
  modules: ['DesignItems']
});

/**
 * Loadable import for Items container
 */
const OrderItems = Loadable({
  loader: () => import(/* webpackChunkName: "DesignItems" */ './OrderItems'),
  loading: () => null,
  modules: ['OrderItems']
});

/**
 * Loadable import for Compilations container
 */
const Compilations = Loadable({
  loader: () => import(/* webpackChunkName: "Compilations" */ './Compilations'),
  loading: () => null,
  modules: ['Compilations']
});


/**
 * Loadable import for PremadeFiles container
 */
const PremadeFiles = Loadable({
  loader: () => import(/* webpackChunkName: "PremadeFiles" */ './PremadeFiles'),
  loading: () => null,
  modules: ['PremadeFiles']
});

/**
 * Loadable import for PremadeFiles container
 */
const PremadeFileDisplay = Loadable({
  loader: () => import(/* webpackChunkName: "PremadeFileDisplay" */ './PremadeFileDisplay'),
  loading: () => null,
  modules: ['PremadeFileDisplay']
});



// /**
//  * Loadable import for Items container
//  */
// const Items = Loadable({
//   loader: () => import(/* webpackChunkName: "Items" */ './Items'),
//   loading: () => null,
//   modules: ['Items']
// });

/**
 * Loadable import for ItemDisplay container
 */
const ItemDisplay = Loadable({
  loader: () => import(/* webpackChunkName: "ItemDisplay" */ './ItemDisplay'),
  loading: () => null,
  modules: ['ItemDisplay']
});

// DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
// new scaffolded loadable imports

/**
 * The Root container used as entry point for the react-router. 
 * All routes are lazy-loaded with Loadable.
 */
class Root extends Component {

  render() {
  const {currentUser, alertMessage, alertColor, alertLink, alertOrigin, alertTimeout} = this.props;
  const can_view_play_mode = currentUser && hasPermission(currentUser, {view_play_mode: 'Item'})
  const can_view_production_mode = currentUser && hasPermission(currentUser, {view_production_mode: 'Item'})
  const can_view_status_mode = currentUser && hasPermission(currentUser, {view_status_mode: 'Item'})
  const navBarSearchSettings = {
    can_view_play_mode,
    can_view_production_mode,
    can_view_status_mode,
    location: this.props.location.pathname,
  }
  return (
      <StickyContainer id="Root">
        <OfflineBar />
        
        <Sticky topOffset={1} disableCompensation>
          { ({style, isSticky}) => 
          <div>
            <Navbar style={{...style}} isSticky={isSticky} navBarSearchSettings={navBarSearchSettings}/>
            { alertMessage && 
              <FlashAlert style={{...style}} color={alertColor || 'success'} timeout={alertTimeout} origin={alertOrigin} link={alertLink}>{alertMessage}</FlashAlert>
            }
          </div>
          }
        </Sticky>
        <div id="content">
          <Switch>
            <Route path={"/"} exact component={Home} />
            <Route exact path="/home" component={Home} />
            <Route path={"/account/login"} component={Account} />
            <Route path={"/account/recover"} component={Account} />
            <Route path={"/account/reset"} component={Account} />
            <PrivateRoute path={'/about/:filter?'} component={About} />
            <PrivateRoute path={'/dashboard'} component={ShippingStatisticDashboard} />
            <PrivateRoute path={'/statistics'} component={StatisticDashboard} />
            <Route path="/compilations" component={Compilations} />
            <Route path="/items" component={Items} />
            <PrivateRoute exact path={'/items/new'} component={ItemDisplay} />
            <PrivateRoute path={'/items/:id/edit'} component={ItemDisplay} />

            { can_view_play_mode && 
              <Route path={'/design'} component={DesignItems} />
            }
            { can_view_play_mode &&
                <Route exact path={'/premade_files'} component={PremadeFiles} />
            }
            { can_view_play_mode &&
                <Route exact path={'/premade_files/new'} component={PremadeFileDisplay} />
            }
            { can_view_play_mode &&
                <Route path={'/premade_files/:id/edit'} component={PremadeFileDisplay} />
            }
            { can_view_production_mode && 
              <Route path={'/production'} component={ProductionItems} />
            }
            { can_view_status_mode && 
              <Route path={'/orderitem'} component={OrderItems} />
            }
            
            {/* // DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT */}
            {/* new routes */}
          </Switch>
        </div>
      </StickyContainer>
    );
  }
}

/**
 * Selects props from the store and passes them to the component
 * @param {Object} state the store
 */
const mapStateToProps = state => ({
  currentUser: state.account.currentUser,
  alertMessage: state.notifications.message,
  alertColor: state.notifications.color,
  alertTimeout: state.notifications.timeout,
  alertLink: state.notifications.link,
  alertOrigin: state.notifications.origin,
});
/**
 * Binds dispatch method to the actions passed as props to the component
 */
const mapDispatchToProps = dispatch =>
  bindActionCreators({}, dispatch);


/** define proptypes for the component */
Root.propTypes = {
  currentUser: PropTypes.object.isRequired,
  alertMessage: PropTypes.string,
  alertColor: PropTypes.string,
  alertLink: PropTypes.string,
  alertTimeout: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
  alertOrigin: PropTypes.string,
};


/** export the redux connected component and add a frontload call */
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Root)
);