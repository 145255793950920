/**
 *  Sagas for the user account management
 * 
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a succes or a failure action
 */
import { call, put, takeEvery } from 'redux-saga/effects'
import actions from './account-actions';
import servWorkActions from '../servWork';
import Swagger from 'swagger-client'
import { push } from 'connected-react-router'

const API_URL = process.env.REACT_APP_API_SERVER
const WEB_URL = process.env.REACT_APP_WEB_SERVER

/** Worker Sagas */

/** Login Saga
 *  @description: passes username and password to the `auth/sign_in` to login the user
 *  - redirects to dashboard on success
 *  - sends push subscription data to the server after success 
 *  @param {Object} action.payload username and password
 */
export function* login(action) {
   try {
      const payload = yield call(authApiRequest, `${API_URL}/auth/sign_in`, {
        method: 'POST',
        body: JSON.stringify(action.payload),
        headers: {'Content-Type': 'application/json'},
      });
      yield put({type: actions.loginSuccess, payload: payload});
      yield put({type: servWorkActions.sendPushSubscription});
      yield put(push('/'));
   } catch (e) {
      yield put({type: actions.loginFail, error: e});
   }
}

/** Logout Saga
 *  @description: connects to auth/signout endpoint to log out the user
 *  - redirects to landing
 *  @param {Object} action.payload the user tokens
 */
export function* logout(action) {
   try {
      const payload = yield call(authApiRequest, `${API_URL}/auth/sign_out`, {
        method: 'DELETE',
        body: JSON.stringify(action.payload),
        headers: {'Content-Type': 'application/json'},
      });
      yield put({type: actions.logoutSuccess, payload: payload});
      yield put(push('/'));
   } catch (e) {
      yield put({type: actions.logoutFail, error: e});
   }
}

/** Social Login Saga
 *  @description: connects to auth/facebook endpoint to login the user with social data
 *  @param {Object} action.payload the user tokens
 */
export function* socialLogin() {
   try {
      const payload = yield call(authApiRequest, `${API_URL}/auth/facebook`);
      yield put({type: actions.loginSuccess, payload: payload});
   } catch (e) {
      yield put({type: actions.loginFail, error: e});
   }
}



/** Recovery Saga
 *  @description: sends a password recovery request for a user
 *  - redirects to login
 *  @param {Object} action.payload the email address to recover
 */
export function* pwdRecovery(action) {
  action.payload.redirect_url = `${WEB_URL}/account/reset`
   try {
      const payload = yield call(authApiRequest, `${API_URL}/auth/password`, {
        method: 'POST',
        body: JSON.stringify(action.payload),
        headers: {'Content-Type': 'application/json'},
      });
      yield put({type: actions.pwdRecoverySuccess, payload: payload});
      yield put(push('/account/login'));
   } catch (e) {
      yield put({type: actions.pwdRecoveryFail, error: e});
   }
}

/** Reset Saga
 *  @description: sends a password reset request for a user
 *  - redirects to dashboard
 *  @param {Object} action.payload the authentication tokens
 */
export function* pwdReset(action) {
  var body = action.payload;
  var headers = {
    'Content-Type': 'application/json',
    'access-token' : body.headers['access-token'],
    'client': body.headers.client,      
    'uid': body.headers.uid, 
  }
  delete body.headers;
   try {
      const payload = yield call(authApiRequest, `${API_URL}/auth/password`, {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: headers,
      });
      yield put({type: actions.pwdResetSuccess, payload: payload});
      yield put(push('/'));
   } catch (e) {
      yield put({type: actions.pwdResetFail, error: e});
   }
}

/** The saga watchers */
function* authSaga() {
  yield takeEvery(actions.login, login);
  yield takeEvery(actions.socialLogin, socialLogin);
  yield takeEvery(actions.logout, logout);
  yield takeEvery(actions.pwdRecovery, pwdRecovery);
  yield takeEvery(actions.pwdReset, pwdReset);
}

export default authSaga;

/**
 * Custom api request for authentication methods
 * @param {string} _url the endpoint url
 * @param {*} args the options object holding headers, body and method
 */
const authApiRequest = (_url, args) => {
  const request = {
    url: _url,
    method: args.method,
    body: args.body,
    headers: args.headers,
  }
  return Swagger.http(request)
  .then((res) => {
    var result = res.body
    result.data.tokens = res.headers;
    return result;
  })
  .catch((err) => {
    console.log(err);
    if (err.response && err.response.obj) throw err.response.obj;
    throw err
  })
};
