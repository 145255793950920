/**
 * Actions and ActionTypes definitions for the Item resource managment.
 * ActionTypes are the constants used to specify a specific action.
 * Actions are generated with redux-actions' createAction method.
 * Each action involving a fetch request also defines a SUCCESS and FAIL action called after the fetch result
 */
import { createAction } from "redux-actions";
import notificationsActions from "../notifications";

/** LIST items action-types */
const LIST = "items/LIST";
const LIST_SUCCESS = "items/LIST_SUCCESS";
const LIST_FAIL = "items/LIST_FAIL";

/** SEARCH items action-types */
const SEARCH = "items/SEARCH";
const SEARCH_SUCCESS = "items/SEARCH_SUCCESS";
const SEARCH_FAIL = "items/SEARCH_FAIL";

/** FILTER items action-types */
const FILTER = "items/FILTER";
const FILTER_SUCCESS = "items/FILTER_SUCCESS";
const FILTER_FAIL = "items/FILTER_FAIL";

/** SKIP items action-types */
const SKIP = "items/SKIP";
const SKIP_SUCCESS = "items/SKIP_SUCCESS";
const SKIP_FAIL = "items/SKIP_FAIL";

/** ORDER_REVIEW items action-types */
const ORDER_REVIEW = "items/ORDER_REVIEW";
const ORDER_REVIEW_SUCCESS = "items/ORDER_REVIEW_SUCCESS";
const ORDER_REVIEW_FAIL = "items/ORDER_REVIEW_FAIL";

/** SKIP items action-types */
const IMPORT_FILES = "items/IMPORT_FILES";
const IMPORT_FILES_SUCCESS = "items/IMPORT_FILES_SUCCESS";
const IMPORT_FILES_FAIL = "items/IMPORT_FILES_FAIL";

/** SKIP items action-types */
const GENERATE_PRODUCTION_FILE = "items/GENERATE_PRODUCTION_FILE";
const GENERATE_PRODUCTION_FILE_SUCCESS = "items/GENERATE_PRODUCTION_FILE_SUCCESS";
const GENERATE_PRODUCTION_FILE_FAIL = "items/GENERATE_PRODUCTION_FILE_FAIL";

/** SHOW item action-types */
const SHOW = "items/SHOW";
const SHOW_SUCCESS = "items/SHOW_SUCCESS";
const SHOW_FAIL = "items/SHOW_FAIL";

/** CREATE item action-types */
const CREATE = "items/CREATE";
const CREATE_SUCCESS = "items/CREATE_SUCCESS";
const CREATE_FAIL = "items/CREATE_FAIL";

/** UPDATE item action-types */
const UPDATE = "items/UPDATE";
const UPDATE_SUCCESS = "items/UPDATE_SUCCESS";
const UPDATE_FAIL = "items/UPDATE_FAIL";

/** REMOVE item action-types */
const REMOVE = "items/REMOVE";
const REMOVE_SUCCESS = "items/REMOVE_SUCCESS";
const REMOVE_FAIL = "items/REMOVE_FAIL";

/** STORE item action-type */
const STORE = "items/STORE";
/** UNSTORE item action-type */
const UNSTORE = "items/UNSTORE";

/**
 * list items
 */
const listSuccess = createAction(LIST_SUCCESS);
const listFail = createAction(LIST_FAIL);
const list = createAction(LIST);

/**
 * search items
 */
const searchSuccess = createAction(SEARCH_SUCCESS);
const searchFail = createAction(SEARCH_FAIL);
const search = createAction(SEARCH);

/**
 * change item filters
 */
const filterSuccess = createAction(FILTER_SUCCESS);
const filterFail = createAction(FILTER_FAIL);
const filter = createAction(FILTER);

/**
 * change item skips
 */
const skipSuccess = createAction(SKIP_SUCCESS);
const skipFail = createAction(SKIP_FAIL);
const skip = createAction(SKIP);

/**
 * order review
 */
const createOrderReviewSuccess = createAction(ORDER_REVIEW_SUCCESS);
const createOrderReviewFail = createAction(ORDER_REVIEW_FAIL);
const createOrderReview = createAction(ORDER_REVIEW);

/**
 * change item importFiles
 */
const importFilesSuccess = createAction(IMPORT_FILES_SUCCESS);
const importFilesFail = createAction(IMPORT_FILES_FAIL);
const importFiles = createAction(IMPORT_FILES);

/**
 * change item generateProductionFile
 */
const generateProductionFileSuccess = createAction(
  GENERATE_PRODUCTION_FILE_SUCCESS
);
const generateProductionFileFail = createAction(GENERATE_PRODUCTION_FILE_FAIL);
const generateProductionFile = createAction(GENERATE_PRODUCTION_FILE);

/**
 * show item
 */
const showSuccess = createAction(SHOW_SUCCESS);
const showFail = createAction(SHOW_FAIL);
const show = createAction(SHOW);

/**
 * create item
 */
const createSuccess = createAction(CREATE_SUCCESS);
const createFail = createAction(CREATE_FAIL);
const create = createAction(CREATE);

/**
 * update item
 */
const updateSuccess = createAction(UPDATE_SUCCESS);
const updateFail = createAction(UPDATE_FAIL);
const update = createAction(UPDATE);

/**
 * remove item
 */
const removeSuccess = createAction(REMOVE_SUCCESS);
const removeFail = createAction(REMOVE_FAIL);
const remove = createAction(REMOVE);

/**
 * store item
 */
const storeItem = createAction(STORE, () => async (dispatch) => {
  if (navigator && "storage" in navigator && "estimate" in navigator.storage) {
    navigator.storage.estimate().then(function (estimate) {
      const { usage, quota } = estimate;
      const percentUsed = Math.round((usage / quota) * 100);
      const usageInMib = Math.round(usage / (1024 * 1024));
      const quotaInMib = Math.round(quota / (1024 * 1024));
      const message = `${usageInMib} out of ${quotaInMib} MiB used (${percentUsed}%)`;
      const color = percentUsed > 80 ? "danger" : "success";
      let alertOptions = { message, color, origin: "storage", timeout: 5 };
      dispatch(notificationsActions.showAlert(alertOptions));
    });
  }
});
/**
 * unstore item
 */
const unstoreItem = createAction(UNSTORE);

/** exports all actions as default */
export default {
  list: list,
  listSuccess: listSuccess,
  listFail: listFail,
  search: search,
  searchSuccess: searchSuccess,
  searchFail: searchFail,
  filter: filter,
  filterSuccess: filterSuccess,
  filterFail: filterFail,
  skip: skip,
  skipSuccess: skipSuccess,
  skipFail: skipFail,
  importFiles: importFiles,
  importFilesSuccess: importFilesSuccess,
  importFilesFail: importFilesFail,
  generateProductionFile: generateProductionFile,
  generateProductionFileSuccess: generateProductionFileSuccess,
  generateProductionFileFail: generateProductionFileFail,
  show: show,
  showSuccess: showSuccess,
  showFail: showFail,
  create: create,
  createSuccess: createSuccess,
  createFail: createFail,
  update: update,
  updateSuccess: updateSuccess,
  updateFail: updateFail,
  remove: remove,
  removeSuccess: removeSuccess,
  removeFail: removeFail,
  storeItem: storeItem,
  unstoreItem: unstoreItem,
  createOrderReview: createOrderReview,
  createOrderReviewSuccess: createOrderReviewSuccess,
  createOrderReviewFail: createOrderReviewFail,
};
