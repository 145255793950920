/**
 *  Reducer function for the itemParts resource management
 * 
 * It exports a defaultState used as initial state for this reducer.
 * Each action is handled with the handleActions method from redux-actions and is bound to the actions defined in actions/itemParts
 * 
 */
import { handleActions } from 'redux-actions';
import actions from './itemParts-actions';


/** The itemParts reducer initial state */
export const defaultState = {
  filters: {},
  list: [],
  loadingList: false,
  loadedList: false,
  listError: null,
  show: null,
  loadingShow: false,
  loadedShow: false,
  showError: null,
  creating: false,
  created: false,
  createError: null,
  updating: false,
  updated: false,
  updateError: null,
  removing: false,
  removed: false,
  removeError: null
};

/** List of actions handled by reducer */
export default handleActions(
  {
    /** The itemParts list action trigger
     * @param {Object} state the current state of this reducer
     */
    [actions.filter]: (state, action) => {
      let newFilters = { ...state.filters }
      Object.keys(action.payload).map(filterKey => newFilters[filterKey] = action.payload[filterKey]);
      return {
        ...state,
        filters: newFilters
      };
    },
    /** The itemParts list action trigger
     * @param {Object} state the current state of this reducer
     */
    [actions.list]: (state) => {
      return {
        ...state,
        loadingList: true,
        loadedList: false,
        listError: null,
      };
    },
    /** The itemParts list action success
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the list of itemParts
     */
    [actions.listSuccess]: (state, action) => {
      return {
        ...state,
        loadingList: false,
        loadedList: true,
        list: action.payload
      };
    },
    /** The itemParts list action fail
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the error message
     */
    [actions.listFail]: (state, action) => {
      return {
        ...state,
        loadingList: false,
        loadedList: false,
        listError: action.payload.mess
      };
    },
    /** The item show action trigger
     * @param {Object} state the current state of this reducer
     */
    [actions.show]: (state) => {
      return {
        ...state,
        loadingShow: true,
        loadedShow: false,
        showError: null,
      };
    },
    /** The item show action success
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the requested item
     */
    [actions.showSuccess]: (state, action) => {
      return {
        ...state,
        loadingShow: false,
        loadedShow: true,
        show: action.payload
      };
    },
    /** The item show action fail
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the error message
     */
    [actions.showFail]: (state, action) => {
      return {
        ...state,
        loadingShow: false,
        loadedShow: false,
        showError: action.payload.mess
      };
    },
    /** The item create action trigger
     * @param {Object} state the current state of this reducer
     */
    [actions.create]: (state) => {
      return {
        ...state,
        creating: true,
        created: false,
        createError: null,
      };
    },
    /** The item create action success
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the created item
     */
    [actions.createSuccess]: (state, action) => {
      var newList = state.list.push(action.payload)
      return {
        ...state,
        list: newList,
        creating: false,
        created: true,
        show: action.payload
      };
    },
    /** The item create action fail
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the error message
     */
    [actions.createFail]: (state, action) => {
      return {
        ...state,
        creating: false,
        created: false,
        createError: action.payload.mess
      };
    },
    /** The item update action trigger
     * @param {Object} state the current state of this reducer
     */
    [actions.update]: (state) => {
      return {
        ...state,
        updating: true,
        updated: false,
        updateError: null,
      };
    },
    /** The item update action success
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the updated of itemParts
     */
    [actions.updateSuccess]: (state, action) => {
      return {
        ...state,
        updating: false,
        updated: true,
        show: action.payload
      };
    },
    /** The item update action fail
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the error message
     */
    [actions.updateFail]: (state, action) => {
      return {
        ...state,
        updating: false,
        updated: false,
        updateError: action.payload
      };
    },
    /** The item remove action trigger
     * @param {Object} state the current state of this reducer
     */
    [actions.remove]: (state) => {
      return {
        ...state,
        removing: true,
        removed: false,
        removeError: null,
      };
    },
    /** The item remove action success
     * @param {Object} state the current state of this reducer
     */
    [actions.removeSuccess]: (state) => {
      return {
        ...state,
        removing: false,
        removed: true,
      };
    },
    /** The item remove action fail
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the error message
     */
    [actions.removeFail]: (state, action) => {
      return {
        ...state,
        removing: false,
        removed: false,
        removeError: action.payload.mess
      };
    },
    /** The store item action. Saves the currently shown item in the persisted storage
     * @param {Object} state the current state of this reducer
     */
    [actions.storeItemPart]: (state) => {
      let newStoredItemParts = Object.assign({}, state.stored)
      newStoredItemParts[state.show.id] = state.show;
      let newShow = Object.assign({}, state.show);
      newShow.stored = true;
      return {
        ...state,
        stored: newStoredItemParts,
        show: newShow
      };
    },
    /** The unstore item action. Removes the currently shown item from the persisted storage
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the id of the item to unstore
     */
    [actions.unstoreItemPart]: (state, action) => {
      let newStoredItemParts = Object.assign({}, state.stored)
      delete newStoredItemParts[action.payload];
      let newShow = Object.assign({}, state.show);
      newShow.stored = false;
      return {
        ...state,
        stored: newStoredItemParts,
        show: newShow
      };
    }
  },
  defaultState
);
