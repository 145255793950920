/**
 *  Reducer function for the show/hide notifications actions
 * 
 * It exports a defaultState used as initial state for this reducer.
 * Each action is handled with the handleActions method from redux-actions and is bound to the actions defined in actions/notifications
 * 
 */
import { handleActions } from 'redux-actions';
import actions from './notifications-actions';


/** The items reducer initial state */
export const defaultState = {
  message: null,
  origin: '',
  color: '',
  timeout: false,
  link: ''
};

/** List of actions handled by reducer */
export default handleActions(
  {
    /** The show alert action
     * @param {Object} state the current state of this reducer
     * @param {Object} action.payload the flash message, the color and the timeout
     */
    [actions.showAlert]: (state, action) => {
      return {
        ...state,
        message: action.payload.message,
        origin: action.payload.origin,
        color: action.payload.color,
        timeout: action.payload.timeout,
        link: action.payload.link
      };
    },
    /** The hide alert action
     * @param {Object} state the current state of this reducer
     */
    [actions.hideAlert]: () => {
      return defaultState;
    }
  },
  defaultState
);
