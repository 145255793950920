export const translations = {
  'de': {
    'status': 'Status',
    'scope': 'Bereich',
    'laminate': 'Laminat',
    'material': 'Material',
    'refresh': 'aktualisieren',
    'Download': 'Herunterladen',
    'Start': 'Start',
    'SKU': 'SKU',
    'order id': 'Bestellnummer',
    'Amount is beeing calculated': 'Anzahl wird berechnet',
    'Amount': 'Anzahl',
    'Broken': 'Zerstört',
    'Complete': 'Fertig',
    'print barcode': 'Barcode drucken',
    'cancel compilation': 'Sammelproduktion abbrechen',
    'i have printed all barcodes with pixi': 'Ich habe alle Barcodes gedruckt',
    'finish plott and update status': 'Sammelproduktion abschließen und Status aktualisieren',
    'New content is available. Please click on this notification to refresh the page': 'Neue Inhalte sind verfügbar. Bitte klicken Sie auf diese Benachrichtigung, um die Seite zu aktualisieren',
    'Send Message to Customer': 'Nachricht an Kunden senden',
    'Contact customer': 'Kunden kontaktieren',
    'Remove this issue': 'Problem entfernen',
    'Remove': 'löschen',
    'Orignal Price': 'Orignal Preis',
    'New Price': 'Neuer Preis',
    'Price Difference': 'Preis Differenz',
    'dc.solution.size_price_warning': 'Achtung, der Preis wird nur geschätzt. Bitte im Konfigurator auf myfolie.com die Produktionsart und Maße angeben. Sollte der Unterschied mehr als 5€ sein bitte den Preis hier angegeben.',
    'dc.solution.file_upload.description': 'Der Kunde kann eine neue Datei hochladen',
    'Upload new File': 'Neue Datei hochladen',
    'dc.solution.vector_file_upload.description': 'Kunde wird gebeten eine neue Vektor Datei hochzuladen',
    'dc.solution.confirm_size.description': 'Kunde wird gebeten die bestellte Größe zu bestätigen',
    'Confirm Size': 'Größe bestätigen',
    'dc.solution.confirm_filequality.description': 'Kunde wird gebeten die Datei Qualität zu bestätigen (DPI bei bestellter Größe)',
    'Confirm File': 'Datei bestätigen',
    'dc.solution.change_cutting_to_rectangular.description': 'Schnitte auf RECHTECKIG ändern, die Produktionsmethode beibehalten',
    'Confirm new Cutting': 'Geänderten Schnitt bestätigen',
    'dc.solution.vectorize_with_surcharge.description': 'Kunde wird gebeten den Preis für die Vektorisierung zu bestätigen. Den Preis bitte von Compuzz nehmen / 6 teilen und * 10 nehmen. Beispiele:\n\n12€ = 12 / 6 = 20\n18€ = 18 / 6 = 30\n24€ = 24 / 6 = 40\n30€ = 30 / 6 = 50',
    'Vectorize': 'Vektorisierung',
    'dc.solution.vectorize_with_surcharge_9_90.description': 'Vektorisierung für 9,90€ bestätigen (Standard Tarif, eine Einheit bei Compuzz)',
    'Vectorize for 9,90€': 'Vektorisierung für 9,90€ freigeben',
    'dc.solution.vectorize_with_surcharge_unkown.description': 'Die Vektorisierung ist komplex und vermutlich teurer als 9,90€. Diese Option bietet dem Kunden an, bei Compuzz ein Angebot einzuholen und ihm den Aufpreis zu nennen.',
    'Request Vectorization Offer': 'Angebot zur Vektorisierung anfragen',
    'dc.solution.contact_service_email.description': 'Kunde wird gebeten den Support per E-Mail zur Lösung zu kontaktieren wenn keine der vorgeschlagenen Optionen zutreffend ist.',
    'Open Mail': 'E-Mail senden',
    'dc.solution.change_production_method.description': 'Produktionsmethode ändern',
    'Confirm new Production Method': 'Produktionsmethode bestätigen',
    'add an issue for this position': 'Ein Problem für dieses Position hinzufügen',
    'Message Preview': 'Nachrichten Vorschau',
    'Please select at least one Problem and one Solution to ask the client for Feedback': 'Bitte wähle mindestens ein Problem und eine Lösung aus um den Kunden zu kontaktieren',
    'The customer will be notified within an hour by E-Mail': 'Der Kunde wird innerhalb der nächsten Stunde automatisch angeschrieben',
    'confirm & notify client': 'Speichern & Kunden benachrichtigen',
    'cancel': 'Abbrechen',
    'add proposed solution for this issue': 'Eine weitere Lösung hinzufügen',
    'dc.problem.size_too_small_ohg.title': 'Teile des Motives sind zu klein für eine Produktion ohne Hintergrund / freigestellt',
    'dc.problem.white_on_white.title': 'DATENCHECK: Weißes Motiv auf weißem Untergrund',
    'dc.problem.low_contrast.title': 'DATENCHECK: Niedriger Kontrast bei Druck auf weißem Untergrund',
    'dc.problem.size_too_big.title': 'Das Motiv ist zu groß für eine Produktion an einem Stück',
    'dc.problem.size_confirmation.title': 'DATENCHECK: Kunden um bestätigung der größe Bitten (MM mit CM verwechselt?)',
    'dc.problem.size_too_small.title': 'Das Motiv ist zu klein zur Produktion in der gewählten Produktionsart',
    'dc.problem.file_quality_bad.title': 'DATENCHECK: Dateiqualität ist schlecht (geringe DPI)',
    'dc.problem.vectorization_ohg.title': 'Für ohne Hintergrund / freigestellt wird eine Vektordatei benötigt',
    'dc.problem.vectorization_required.title': 'Für die Produktion wird eine Vektordatei benötigt',
    'dc.problem.file_not_received.title': 'FILE: Datei ist nicht bei uns angekommen',
    'dc.problem.file_missing.title': 'FILE: Fehlende Datei / Bitte Datei nachreichen (Upload Error)',
    'dc.problem.file_broken.title': 'FILE: Datei beschädigt: Die Datei kann nicht geöffnet werden',
    'dc.problem.font_not_embedded.title': 'Schriftart ist nicht eingebettet bzw. vektorisiert',
    'dc.problem.image_not_embedded.title': 'Ein Bild / Element ist nicht eingebettet und fehlt',
    'dc.problem.qr_code_ohg.title': 'QR Codes können nicht Ohne Hintergrund / Freigestellt produziert werden',
    'dc.problem.vectorization_offer_confirmation.title': 'Vektorisierung: Aufpreis durch Kunden bestätigen lassen',
    'dc.solution.confirm_size.title': 'Größe wie bestellt bestätigen',
    'dc.solution.confirm_filequality.title': 'Motiv Qualität wie bestellt bestätigen',
    'dc.solution.change_size_with_surcharge.title': 'Größe mit Aufpreis ändern (größer)',
    'dc.solution.change_size_with_refund.title': 'Größe mit Rückerstattung ändern (Deutlich kleiner)',
    'dc.solution.change_size_cost_neutral.title': 'Größe ohne Preisänderung anpassen (weniger als 5€ unterschied)',
    'dc.solution.split_design_in_multiple_parts.title': 'Motiv teilen (z.B Klebeschriften, komplexe Motive)',
    'dc.solution.split_design_in_multiple_parts_with_info.title': 'Motiv teilen (Wie auf Webseite angezeigt)',
    'dc.solution.file_upload.title': 'Datei Hochladen (Egal Welche)',
    'dc.solution.vector_file_upload.title': 'Vektor Datei hochladen',
    'dc.solution.vectorize_with_surcharge.title': 'Vektorisierung mit Aufpreis bestätigen (Angebot von Compuzz)',
    'dc.solution.vectorize_with_surcharge_9_90.title': 'Vektorisierung für 9,90€',
    'dc.solution.vectorize_with_surcharge_unkown.title': 'Komplexe Vektorisierung, Angebot muss eingeholt werden',
    'dc.solution.change_method_to_transparent_print.title': 'Auf TRANSPARENTE Klebefolie drucken',
    'dc.solution.change_method_to_transparent_print_rectangular_cutting.title': 'Auf TRANSPARENTE Klebefolie drucken und RECHTECKIG schneiden',
    'dc.solution.change_method_to_normal_print.title': 'Auf WEIßE Klebefolie drucken',
    'dc.solution.change_method_to_normal_print_rectangular_cutting.title': 'Auf WEIßE Klebefolie drucken und RECHTECKIG schneiden',
    'dc.solution.change_method_to_monochrome_cutting.title': 'Produktionsart auf Schneideplott ändern',
    'dc.solution.contact_service_email.title': 'Kontakt per E-Mail aufnehmen',
    'dc.solution.file_email.title': 'Datei per E-Mail senden',
    'dc.solution.cancelation.title': 'Storno anbieten (wenn es keine Lösung gibt)',
    'Selected Filters:': 'Ausgewählte Filter:',
    'Amazon': 'Amazon',
    'Only Individual Blankcuts': 'Nur individuelle Zuschnitte',
    'roll width': 'Rollenbreite',
    'all': 'Alle',
    '630mm': '630mm',
    '1000mm': '1000mm',
    '1260mm': '1260mm',
    'Claim Reason': 'Grund der Reklamation',
    'unkown': 'Unbekannt',
    'claim_reasons.all_shipping_required': 'Alle Reklamationen zum erneuten Versand',
    'shipping_address_failure': 'Adresse Fehlerhaft',
    'shipping_address_not_found': 'Adresse nicht gefunden (Zustellung)',
    'shipping_resend_for_other_reason': 'Erneuter Versand aus sonstigem Grund',
    'claim_reasons.all_production_required': 'Alle Reklamationen zur erneuten Produktion',
    'goodwill': 'Kulanz (erneute Produktion)',
    'product_missing': 'Produkt hat gefehlt',
    'damaged_shipping': 'Beschädigt beim Versand',
    'damaged_weeding': 'Beschädigt beim Entgittern',
    'damaged_cutting': 'Beschädigt beim Zuschnitt',
    'damaged_plotting': 'Beschädigt beim Plotten',
    'claim_reasons.all_production_file_required': 'Alle Reklamationen die erneut angelegt werden müssen',
    'production_file_malformed': 'Produktionsdatei fehlerhaft (z.B. Größe, Farbe, …)',
    'production_method_wrong': 'Produktions methode falsch',
    'any laminate': 'Alle Laminate',
    'No Laminate': 'Kein Laminat',
    'Filecheck Mode': 'Datencheck Modus',
    'any filecheck status': 'Alle Datenchecks',
    'only filecheck complete': 'Nur durchgeführte/laufende Datenchecks',
    'only new filechecks': 'Nur neue Datenchecks',
    'only filecheck recangular': 'Nur rechteckige Datenchecks',
    'any material': 'Alle Materialien',
    'serie': 'Folienserie',
    'Any Serie': 'Alle Serien',
    'color': 'Farbe',
    'Any Color': 'Alle Farben',
    'please select series': 'Bitte Folienserie wählen',
    'Order ID': 'Bestellnummer',
    'priority': 'Priorität',
    'any priority': 'Alle Prioritäten',
    'express': 'Express',
    'high': 'Hoch',
    'medium': 'Medium',
    'Only Produceable': 'Keine Lagerartikel',
    'Rectangular Cutting': 'Rechteckiger Schnitt',
    'select status': 'Status wählen',
    'Name': 'Name',
    'Description': 'Beschreibung',
    'Submit': 'Absenden',
    'Material': 'Material',
    'Current State': 'Aktueller Status',
    'Items processed': 'Verarbeitete Positionen',
    'New State': 'Neuer Status',
    'From': 'Von',
    'To': 'Nach',
    'Mandatory: Reason for Status change?': 'Mandatory: Reason for Status change?',
    'Benötigt: Grund für die Status Änderung?': 'Benötigt: Grund für die Status Änderung?',
    '-- Please Choose / Bitte wählen --': '— Bitte wählen —',
    'File is broken / Datei is fehlerhaft': 'Datei is fehlerhaft',
    'Damaged at Weeding / Entgitter Fehler': 'Entgitter Fehler',
    'Damaged at Cutting / Motiv Zerschnitten (Manuell)': 'Motiv Zerschnitten (Manuell)',
    'Damaged at Plotting / Motiv Zerschnitten (Plotten)': 'Motiv Zerschnitten (Plotten)',
    'Damaged at Shippment / Beim Versand beschädigt': 'Beim Versand beschädigt',
    'Damaged at Finishing (Taping / Applying) / Bei der Endfertigung beschädigt (Tapen / Anbringen)': 'Bei der Endfertigung beschädigt (Tapen / Anbringen)',
    'Did not match minimum size / Schrift oder Motiv zu klein': 'Schrift oder Motiv zu klein',
    'Wrong Status in MPT / Status falsch': 'Status falsch',
    'Product can not be found / Produkt ist verloren gegangen': 'Produkt ist verloren gegangen',
    'Removed from Box / Aus Box genommen (PIXI!)': 'Aus Box genommen (PIXI!)',
    'Filecheck complete / Datencheck vollständig': 'Profi-Datencheck vollständig',
    'Other / Sonstiges': 'Sonstiges',
    'Box:': 'Box:',
    'Delivery': 'Lieferung',
    'filecheck': 'Profi-Datencheck',
    'productiondata': 'Produktionsdaten',
    'vectorize': 'Vektorisierung',
    'HGV': 'HGV',
    'Unpaid': 'Unbezahlt',
    'claim': 'Reklamation',
    'normal': 'Normal',
    'calculated': 'berechnet',
    'resolution': 'Auflösung',
    'email': 'Email',
    'Preis': 'Preis',
    'cutting': 'Schnitt',
    'Is Underglass': 'Gespiegelt',
    'Paid At': 'Bezahlt am',
    'create production data': 'Erstellung von Produktionsdaten',
    'box': 'Box',
    'border': 'Rand',
    'crop': 'Zuschnitt',
    'Crop': 'Zuschnitt',
    'Error, please look up raw json': 'Fehler, bitte in den Roh Daten nachsehen',
    'X': 'X',
    'Y': 'Y',
    'Width': 'Breite',
    'Height': 'Höhe',
    'Image': 'Bild',
    'naturalWidth': 'naturalWidth',
    'naturalHeight': 'naturalHeight',
    'parts': 'Einzelteile',
    'description': 'Beschreibung',
    'ItemFilters.select': 'Bitte wählen',
    'ItemTaskScope.digital_print': 'Digital Druck',
    'ItemTaskScope.automated_plott': 'Auto-Schneideplott',
    'ItemTaskScope.manual_plott': 'Manueller Schneideplott',
    'ItemTaskScope.multi_color_plott': 'Mehrfarbiger Schneideplott',
    'ItemTaskScope.blank_film_cut': 'Folienzuschnitt',
    'ItemTaskScope.other': 'Sonstiges',
    'ItemTaskScope.no_filecheck': 'Ohne Profi-Datencheck',
    'ItemTaskScope.with_filecheck': 'Profi-Datencheck',
    'ItemTaskScope.create_production_data': 'Erstellung von Produktionsdaten',
    'ItemTaskScope.design_service': 'Design Service',
    'ItemTaskScope.claims': 'Reklamationen',
    'Summary': 'Zusammenfassung',
    'Full History': 'Gesamte Historie',
    'Raw Object': 'Rohdaten',
    'Change Item Settings': 'Item Einstellungen',
    'Files': 'Dateien',
    'Feedbacks': 'Rückmledungen',
    'other': 'sonstiges',
    'change status': 'Status ändern',
    'delivery': 'Lieferung',
    'shipped at': 'Versendet am',
    'Uploaded': 'Hochgeladen',
    'Update Status': 'Status aktualisieren',
    'Cancel': 'Abbrechen',
    'Preview': 'Vorschau',
    'Item Status': 'Positionsstatus',
    'Production File': 'Produktionsdatei',
    'Filename': 'Dateiname',
    'Filetype': 'Datetyp',
    'current': 'aktuell',
    'change': 'ändern',
    'Upload files': 'Dateien hochladen',
    'Upload for: #': 'Upload für: #',
    'Select File(s) for Upload': 'Dateien für Upload auswählen',
    'Waiting to upload': 'Warten auf den Upload',
    'Uploading': 'Wird hochgeladen',
    'Error uploading ': 'Fehler beim Hochladen ',
    'Finished uploading ': 'Hochladen abgeschlossen ',
    'retry import of files (queued)': 'Wiederholung des Imports von Dateien (in der Warteschlange)',
    'generate rectangular file': 'Rechteckige Produktionsdatei erstellen',
    'audits.noAuditsYet': 'Keine Historiendaten vorhanden',
    'error': 'Fehler',
    'New Item': 'Neue Position',
    '*NEW*': '*NEU*',
    'No Material': 'Kein Material',
    'Edit Item': 'Position bearbeiten',
    'General Settings': 'Allgemeine Konfiguration',
    'Priority': 'Priority',
    'Amount Ordered': 'Bestellte Anzahl',
    'Select claim reason': 'Wähle Reklamationsgrund',
    'Production Settings': 'Produktionskonfiguration',
    'Force Manual Plott': 'Manuelle Produktion erwzingen',
    'Is Surface': 'Folienfläche',
    'Item Parts': 'Einzelteile',
    'Refresh Values': 'Werte aktualisieren',
    'Save item and item parts': 'Speichere Position und Einzelteile',
    'Overwrite status': 'Status überschreiben',
    'Force Shipped': 'Status: Versendet erzwingen',
    'Force Canceled': 'Status: Storniert erzwingen',
    'You really want to set this item to status shipped?': 'Möchtest du wirklich den Status dieser Position auf Versand setzen?',
    'You really want to set this item to status canceled? This will prevent production.': 'Möchtest du wirklich den Status dieser Position auf Storniert setzen? Das Produkt kann dann nicht mehr produziert werden.',
    'series': 'Serie',
    'producer': 'Hersteller',
    'title': 'Titel',
    'key': 'Schlüssel',
    'Email': 'Email',
    'Password': 'Passwort',
    'ForgotPassword': 'Passwort vergessen? Klicke',
    'ForgotPassword.here': 'hier',
    'roll_width': 'Rollenbreite',
    'color_hex': 'HTML Farbcode',
    'color_code': 'Farbcode',
    'subtitle': 'Untertitel',
    'material_group': 'Material Gruppe',
    'No Items found': 'Keine Positionen gefunden',
    'Some notifications': 'Einige Benachrichtigungen',
    'NewPassword': 'Neue Passwort',
    'NewPasswordConfirmation': 'Neues Passwort bestätigen',
    'sku': 'Art. Nr.',
    'active': 'aktiv',
    'PasswordConfirmation': 'Passwort bestätigen',
    'Number': 'Hummer',
    'Text': 'Text',
    'TextArea': 'Textfeld',
    'checkbox': 'Checkbox',
    'Switch': 'Schalter',
    'Male': 'Männlich',
    'Female': 'Weiblich',
    'RadioGroup': 'Radio Gruppe',
    'select': 'Select',
    'multi-select': 'Multi Select',
    'Related Zendesk tickets': 'Zugehörige Zendesk Tickets',
    'created at': 'erstellt',
    'updated at': 'Aktualisiert',
    'No tickets found': 'Keine Tickets gefunden',
    'Compilations List': 'Sammel-Produktion',
    'items': 'Einzelteile',
    'compilation': 'Sammel-Produktion',
    'created': 'erstellt',
    'download': 'Herunterladen',
    'Error uploading': 'Fehler beim hochladen',
    'skip': 'überspringen',
    'NEXT NEW ITEM': 'Nächstes Item (NEU)',
    'back to new': 'Zurück auf NEU',
    'request information': 'Infos Anfragen',
    'Are you sure you want to move all item parts to Information Requested status?': 'Sind Sie sicher, dass Sie alle Positonen in den Status “Informationen angefordert“ verschieben möchten?',
    'Continue': 'Weiter',
    'move back to new': 'Zurück auf NEU',
    'Are you sure you want to move all item parts to New status and set the manual filecheck to complete?': 'Bist du dir sicher, dass du alle Positonen in den Status “Informationen angefordert“ verschieben möchtest?',
    'confim upload': 'Datei-Upload bestätigen',
    'No Preview available': 'Keine Vorschau verfügbar',
    'approval for printing': 'Freigabe der Datei zur Produktion',
    'Start Playmode': 'Starte Playmodus',
    'Home.header.offline': 'ACHTUNG: Du bist gerade offline. Bitte überprüfe die Internetverbindung, du siehst derzeit nur Informationen aus deinem Cache!',
    'loading': 'wird geladen',
    'Store': 'Offline Speichern',
    'Unstore': 'Aus Offline Speicher entfernen',
    'Laminates.header': 'Wilkommen im MPT',
    'Laminates.header.offline': 'ACHTUNG: Sie sind gerade offline. Bitte überprüfen Sie Ihre Internetverbindung, Sie sehen derzeit nur Informationen aus Ihrem Cache!',
    'Materials.header': 'Wilkommen im MPT',
    'Materials.header.offline': 'ACHTUNG: Sie sind gerade offline. Bitte überprüfen Sie Ihre Internetverbindung, Sie sehen derzeit nur Informationen aus Ihrem Cache!',
    'Items in List': 'Positionen in Liste',
    'UNPAID': 'UNBEZAHLT',
    'Paid': 'Bezahlt',
    'Order was paid on:': 'Die Bestellung wurde bezahlt am:',
    'orderstatus.complete': 'vollständig',
    'Order was sent on:': 'Die Bestellung wurde versendet:',
    'orderstatus.pending': 'ausstehend',
    'Planned delivery is on:': 'Die Lieferung ist geplant für:',
    'orderstatus.waiting': 'Wartend',
    'We are waiting for Client feedback': 'Wir warten auf das Feedback unserer Kunden',
    'orderstatus.delayed': 'Verzögert',
    'Order should have been delivered on:': 'Die Bestellung sollte geliefert worden sein am:',
    'orderstatus.broken': 'kaputt',
    'Something needs to be reproduced because it was broken on:': 'Etwas muss erneut produziert werden, weil es beschädigt wurde am:',
    'Usually this means delivery will be delayed for 3 working days after it was broken.': 'In der Regel bedeutet dies, dass sich die Lieferung um 3 Werktage nach dem Schaden verzögert.',
    'back to list': 'Zurück zur Liste',
    'PremadeFiles List': 'Vorangelegte Dateien',
    'refresh list': 'Liste aktualisieren',
    'new': 'Neu',
    'last update': 'Letzte Aktualisierung',
    'edit': 'bearbeiten',
    'max width': 'Max Breite',
    'max length': 'Max Länge',
    'Generate Compilation': 'Sammel-Produktion generieren',
    'ShippingStatisticDashboard.header.offline': 'ACHTUNG: Sie sind gerade offline. Bitte überprüfen Sie Ihre Internetverbindung, Sie sehen derzeit nur Informationen aus Ihrem Cache!',
    'StatisticDashboard.header.offline': 'ACHTUNG: Sie sind gerade offline. Bitte überprüfen Sie Ihre Internetverbindung, Sie sehen derzeit nur Informationen aus Ihrem Cache!',
    'by scope': 'Nach Scope',
    'digital print': 'Digital Druck',
    'automated plott': 'Auto-Sammelplott',
    'manual plott': 'Manueller Plott',
    'multi color plott': 'Mehrfarbiger Schneideplott',
    'blank_film_cut': 'Folienzuschnitt',
    'by status': 'Nach Status',
    'file_ready': 'angelegt',
    'supplier': 'bestellt',
    'printing': 'im Druck',
    'printed': 'gedruckt',
    'plotted': 'geplottet',
    'laminated': 'laminiert',
    'at_weeding': 'beim Entgittern',
    'weeded': 'entgittert',
    'finishing': 'Endfertigung',
    'qualitycheck': 'Qualitycheck',
    'information_requested': 'angeschrieben',
    'no filecheck': 'Ohne Profi-Datencheck',
    'with filecheck': 'Profi-Datencheck',
    'production data': 'Produktionsdaten Erstellung',
    'design service': 'Design Service',
  },
  'options': {
    'plural_rule': 'n != 1',
    'plural_number': '2',
  },
  'en': {
    'status': 'Status',
    'scope': 'scope',
    'laminate': 'Laminate',
    'material': 'Material',
    'refresh': 'refresh',
    'Download': 'Download',
    'Start': 'Start',
    'SKU': 'SKU',
    'order id': 'order id',
    'Amount is beeing calculated': 'Amount is beeing calculated',
    'Amount': 'Amount',
    'Broken': 'Broken',
    'Complete': 'Complete',
    'print barcode': 'print barcode',
    'cancel compilation': 'Cancel Compilation',
    'i have printed all barcodes with pixi': 'i have printed all barcodes with pixi',
    'finish plott and update status': 'finish plott and update status',
    'New content is available. Please click on this notification to refresh the page': 'New content is available. Please click on this notification to refresh the page',
    'Send Message to Customer': 'Send Message to Customer',
    'Contact customer': 'Contact customer',
    'Remove this issue': 'Remove this issue',
    'Remove': 'Remove',
    'Orignal Price': 'Orignal Price',
    'New Price': 'New Price',
    'Price Difference': 'Price Difference',
    'dc.solution.size_price_warning': 'Attention: Please check the price for this cutting and production method on myfolie.com. If the price is very different to this estimation please overwrite it here.',
    'dc.solution.file_upload.description': 'Client is asked to upload a new file that fixes all problems.',
    'Upload new File': 'Upload new File',
    'dc.solution.vector_file_upload.description': 'Client is asked to upload a Vector file to fix the issues.',
    'dc.solution.confirm_size.description': 'Client can confirm the size he ordered',
    'Confirm Size': 'Confirm Size',
    'dc.solution.confirm_filequality.description': 'The file quality',
    'Confirm File': 'Confirm File',
    'dc.solution.change_cutting_to_rectangular.description': 'We ask the client to confirm the change to rectangular cutting.',
    'Confirm new Cutting': 'Confirm new Cutting',
    'dc.solution.vectorize_with_surcharge.description': 'A vectorized file is required for this production method or cutting. Please input the additional charge that the client has to pay for this service.',
    'Vectorize': 'Vectorize',
    'dc.solution.vectorize_with_surcharge_9_90.description': 'A vectorized file is required for this production method or cutting. The price is 9,90€ - only one unit.',
    'Vectorize for 9,90€': 'Vectorize for 9,90€',
    'dc.solution.vectorize_with_surcharge_unkown.description': 'A vectorized file is required for this production method or cutting. Please input the additional charge that the client has to pay for this service.',
    'Request Vectorization Offer': 'Request Vectorization Offer',
    'dc.solution.contact_service_email.description': 'Give the client the possibility to send an email',
    'Open Mail': 'Open Mail',
    'dc.solution.change_production_method.description': 'We ask the client to change the production method, once he confirm we’ll let you know.',
    'Confirm new Production Method': 'Confirm new Production Method',
    'add an issue for this position': 'add an issue for this position',
    'Message Preview': 'Message Preview',
    'Please select at least one Problem and one Solution to ask the client for Feedback': 'Please select at least one Problem and one Solution to ask the client for Feedback',
    'The customer will be notified within an hour by E-Mail': 'The customer will be notified within an hour by E-Mail',
    'confirm & notify client': 'confirm & notify client',
    'cancel': 'Cancel',
    'add proposed solution for this issue': 'add proposed solution for this issue',
    'dc.problem.size_too_small_ohg.title': 'Some components of the design are not suitable for cutting with Freecut.',
    'dc.problem.white_on_white.title': 'FILECHECK: White design on white material',
    'dc.problem.low_contrast.title': 'FILECHECK: Low contrast if printed on white material',
    'dc.problem.size_too_big.title': 'Size is too big to be produced in one piece',
    'dc.problem.size_confirmation.title': 'Size looks like mm should be cm, please confirm size',
    'dc.problem.size_too_small.title': 'Size is too small for production',
    'dc.problem.file_quality_bad.title': 'File Quality is too bad, but it could be produced',
    'dc.problem.vectorization_ohg.title': 'A vectorized file is required for Freecut Cutting',
    'dc.problem.vectorization_required.title': 'A vectorized file is required for this production method or cutting',
    'dc.problem.file_not_received.title': 'FILE: File not received yet',
    'dc.problem.file_missing.title': 'FILE: File is missing / Not Uploaded (Upload Error)',
    'dc.problem.file_broken.title': 'FILE: File is uploaded but broken and can’t beopened',
    'dc.problem.font_not_embedded.title': 'Font is not vectorized or embedded',
    'dc.problem.image_not_embedded.title': 'Image is not embedded in file',
    'dc.problem.qr_code_ohg.title': 'QR Code can’t be produced freecut',
    'dc.problem.vectorization_offer_confirmation.title': 'Vectorization: Ask Client to confirm additional costs (compuzz offer)',
    'dc.solution.confirm_size.title': 'Confirm Size as Ordered',
    'dc.solution.confirm_filequality.title': 'Confirm Bad file Quality',
    'dc.solution.change_size_with_surcharge.title': 'Change size with additional charge (Bigger than ordered)',
    'dc.solution.change_size_with_refund.title': 'Change Size with Refund (Smaller than ordered)',
    'dc.solution.change_size_cost_neutral.title': 'Change Size (without refund, cost neutral, less than 5€ change)',
    'dc.solution.split_design_in_multiple_parts.title': 'Split File in multiple Parts (as mentioned on website)',
    'dc.solution.split_design_in_multiple_parts_with_info.title': 'Split File in multiple Parts (NOT mentioned on website)',
    'dc.solution.file_upload.title': 'Upload new File',
    'dc.solution.vector_file_upload.title': 'Upload Vector File',
    'dc.solution.vectorize_with_surcharge.title': 'Confirm Vecorization and additional charge',
    'dc.solution.vectorize_with_surcharge_9_90.title': 'Vectorization for 9,90€',
    'dc.solution.vectorize_with_surcharge_unkown.title': 'Complex Vectorization, need to ask for Offer',
    'dc.solution.change_method_to_transparent_print.title': 'Confirm Change to TRANSPARENT print on transparent with same cutting',
    'dc.solution.change_method_to_transparent_print_rectangular_cutting.title': 'Confirm Change to TRANSPARENT print on transparent and change cutting to RECTANGULAR',
    'dc.solution.change_method_to_normal_print.title': 'Confirm Change to NORMAL DD (white vinyl) print with same cutting',
    'dc.solution.change_method_to_normal_print_rectangular_cutting.title': 'Confirm Change to NORMAL DD (white vinyl) and change cutting to RECTANGULAR',
    'dc.solution.change_method_to_monochrome_cutting.title': 'Confirm Change to normal Monochrome Cutting',
    'dc.solution.contact_service_email.title': 'Give the client the possibility to send an email (If no other option applies)',
    'dc.solution.file_email.title': 'Send new File by E-mail',
    'dc.solution.cancelation.title': 'Offer Cancelation and Refund of Position',
    'Selected Filters:': 'Selected Filters:',
    'Amazon': 'Amazon',
    'Only Individual Blankcuts': 'Only Individual Blankcuts',
    'roll width': 'roll width',
    'all': 'all',
    '630mm': '630mm',
    '1000mm': '1000mm',
    '1260mm': '1260mm',
    'Claim Reason': 'Claim Reason',
    'unkown': 'Unkown',
    'claim_reasons.all_shipping_required': 'All Claims that need to be shipped',
    'shipping_address_failure': 'Address was wrong on parcel',
    'shipping_address_not_found': 'Address not found by carrier',
    'shipping_resend_for_other_reason': 'Resend for other reason',
    'claim_reasons.all_production_required': 'All Claims that need to be reproduced',
    'goodwill': 'Goodwill',
    'product_missing': 'Product was missing',
    'damaged_shipping': 'Product was damaged during shipment',
    'damaged_weeding': 'Product was damaged at weeding',
    'damaged_cutting': 'Product was damaged at cutting',
    'damaged_plotting': 'Product was damaged at plotting',
    'claim_reasons.all_production_file_required': 'All Claims where a new production file is required',
    'production_file_malformed': 'Something was wrong on the Production file (e.g. size, colors, design, …)',
    'production_method_wrong': 'Production Method was wrong',
    'any laminate': 'Any Laminate',
    'No Laminate': 'No Laminate',
    'Filecheck Mode': 'Filechecks',
    'any filecheck status': 'Any Filecheck Status',
    'only filecheck complete': 'Only completed Filechecks',
    'only new filechecks': 'Only new Filechecks',
    'only filecheck recangular': 'Only Rectangular Filechecks',
    'any material': 'Any Material',
    'serie': 'Series',
    'Any Serie': 'Any Series',
    'color': 'Color',
    'Any Color': 'Any Color',
    'please select series': 'Please select Series',
    'Order ID': 'order id',
    'priority': 'Priority',
    'any priority': 'Any Priority',
    'express': 'EXPRESS',
    'high': 'HIGH',
    'medium': 'MEDIUM',
    'Only Produceable': 'No stock items',
    'Rectangular Cutting': 'Rectangular Cutting',
    'select status': 'Select Status',
    'Name': 'Name',
    'Description': 'Description',
    'Submit': 'Submit',
    'Material': 'Material',
    'Current State': 'Current State',
    'Items processed': 'Items processed',
    'New State': 'New State',
    'From': 'From',
    'To': 'To',
    'Mandatory: Reason for Status change?': 'Mandatory: Reason for Status change?',
    'Benötigt: Grund für die Status Änderung?': 'Benötigt: Grund für die Status Änderung?',
    '-- Please Choose / Bitte wählen --': '— Please Choose —',
    'File is broken / Datei is fehlerhaft': 'File is broken',
    'Damaged at Weeding / Entgitter Fehler': 'Damaged at Weeding',
    'Damaged at Cutting / Motiv Zerschnitten (Manuell)': 'Damaged at Cutting',
    'Damaged at Plotting / Motiv Zerschnitten (Plotten)': 'Damaged at Plotting',
    'Damaged at Shippment / Beim Versand beschädigt': 'Damaged at Shippment',
    'Damaged at Finishing (Taping / Applying) / Bei der Endfertigung beschädigt (Tapen / Anbringen)': 'Damaged at Finishing (Taping / Applying)',
    'Did not match minimum size / Schrift oder Motiv zu klein': 'Did not match minimum size',
    'Wrong Status in MPT / Status falsch': 'Wrong Status in MPT',
    'Product can not be found / Produkt ist verloren gegangen': 'Product can not be found',
    'Removed from Box / Aus Box genommen (PIXI!)': 'Removed from Box',
    'Filecheck complete / Datencheck vollständig': 'Filecheck complete',
    'Other / Sonstiges': 'Other',
    'Box:': 'Box:',
    'Delivery': 'Delivery',
    'filecheck': 'Filecheck',
    'productiondata': 'Productiondata',
    'vectorize': 'Vectorize',
    'HGV': 'HGV',
    'Unpaid': 'Unpaid',
    'claim': 'Claim',
    'normal': 'Normal',
    'calculated': 'Calculated',
    'resolution': 'Resolution',
    'email': 'email',
    'Preis': 'Price',
    'cutting': 'Cutting',
    'Is Underglass': 'Is Underglass',
    'Paid At': 'Paid At',
    'create production data': 'create production data',
    'box': 'Box',
    'border': 'Border',
    'crop': 'crop',
    'Crop': 'Crop',
    'Error, please look up raw json': 'Error, please look up raw json',
    'X': 'X',
    'Y': 'Y',
    'Width': 'Width',
    'Height': 'Height',
    'Image': 'Image',
    'naturalWidth': 'naturalWidth',
    'naturalHeight': 'naturalHeight',
    'parts': 'Einzelteile',
    'description': 'Description',
    'ItemFilters.select': 'Select Production Method',
    'ItemTaskScope.digital_print': 'Digital Print',
    'ItemTaskScope.automated_plott': 'Automated Plott',
    'ItemTaskScope.manual_plott': 'Manual Plott',
    'ItemTaskScope.multi_color_plott': 'Multi Color Plot',
    'ItemTaskScope.blank_film_cut': 'Blank Film Cut',
    'ItemTaskScope.other': 'Others',
    'ItemTaskScope.no_filecheck': 'Without Filecheck',
    'ItemTaskScope.with_filecheck': 'With Filecheck',
    'ItemTaskScope.create_production_data': 'create production data',
    'ItemTaskScope.design_service': 'Design Service',
    'ItemTaskScope.claims': 'Claims',
    'Summary': 'Summary',
    'Full History': 'Full History',
    'Raw Object': 'Raw Object',
    'Change Item Settings': 'Change Item Settings',
    'Files': 'Files',
    'Feedbacks': 'Feedbacks',
    'other': 'Other',
    'change status': 'Change Status',
    'delivery': 'Delivery',
    'shipped at': 'shipped at',
    'Uploaded': 'Uploaded',
    'Update Status': 'Update Status',
    'Cancel': 'Cancel',
    'Preview': 'Preview',
    'Item Status': 'Item Status',
    'Production File': 'Production File',
    'Filename': 'Filename',
    'Filetype': 'Filetype',
    'current': 'current',
    'change': 'change',
    'Upload files': 'Upload Files',
    'Upload for: #': 'Upload for: #',
    'Select File(s) for Upload': 'Select File(s) for Upload',
    'Waiting to upload': 'Waiting to upload',
    'Uploading': 'Uploading',
    'Error uploading ': 'Error uploading ',
    'Finished uploading ': 'Finished uploading ',
    'retry import of files (queued)': 'retry import of files (queued)',
    'generate rectangular file': 'generate rectangular file',
    'audits.noAuditsYet': 'No History Data yet',
    'error': 'error',
    'New Item': 'New Item',
    '*NEW*': '*NEW*',
    'No Material': 'No Material',
    'Edit Item': 'Edit Item',
    'General Settings': 'General Settings',
    'Priority': 'Priority',
    'Amount Ordered': 'Amount Ordered',
    'Select claim reason': 'Select claim reason',
    'Production Settings': 'Production Settings',
    'Force Manual Plott': 'Force Manual Plott',
    'Is Surface': 'Is Surface',
    'Item Parts': 'Item Parts',
    'Refresh Values': 'Refresh Values',
    'Save item and item parts': 'Save item and item parts',
    'Overwrite status': 'Overwrite status',
    'Force Shipped': 'Force Shipped',
    'Force Canceled': 'Force Canceled',
    'You really want to set this item to status shipped?': 'You really want to set this item to status shipped?',
    'You really want to set this item to status canceled? This will prevent production.': 'You really want to set this item to status canceled? This will prevent production.',
    'series': 'Series',
    'producer': 'Producer',
    'title': 'Title',
    'key': 'Key',
    'Email': 'Email',
    'Password': 'Password',
    'ForgotPassword': 'Forgot your password? Recover',
    'ForgotPassword.here': 'here',
    'roll_width': 'Roll width',
    'color_hex': 'Color Hex',
    'color_code': 'Color-code',
    'subtitle': 'Subtitle',
    'material_group': 'Material Group',
    'No Items found': 'No Items Found',
    'Some notifications': 'Some notifications',
    'NewPassword': 'New Password',
    'NewPasswordConfirmation': 'New Password Confirm',
    'sku': 'sku',
    'active': 'active',
    'PasswordConfirmation': 'Password Confirm',
    'Number': 'Number',
    'Text': 'Text',
    'TextArea': 'Text Area',
    'checkbox': 'Checkbox',
    'Switch': 'Switch',
    'Male': 'Male',
    'Female': 'Female',
    'RadioGroup': 'Radio Group',
    'select': 'Select',
    'multi-select': 'Multi Select',
    'Related Zendesk tickets': 'Related Zendesk tickets',
    'created at': 'created at',
    'updated at': 'Update Statusupdated at',
    'No tickets found': 'No tickets found',
    'Compilations List': 'Compilations List',
    'items': 'items',
    'compilation': 'compilation',
    'created': 'created',
    'download': 'Download',
    'Error uploading': 'Error uploading',
    'skip': 'skip',
    'NEXT NEW ITEM': 'NEXT NEW ITEM',
    'back to new': 'back to new',
    'request information': 'request information',
    'Are you sure you want to move all item parts to Information Requested status?': 'Are you sure you want to move all item parts to Information Requested status?',
    'Continue': 'Continue',
    'move back to new': 'move back to new',
    'Are you sure you want to move all item parts to New status and set the manual filecheck to complete?': 'Are you sure you want to move all item parts to New status and set the manual filecheck to complete?',
    'confim upload': 'confim upload',
    'No Preview available': 'No Preview available',
    'approval for printing': 'approval for printing',
    'Start Playmode': 'Start Playmode',
    'Home.header.offline': 'ATTENTION: You are offline right now. Please check your internet connection, you are currently only seeing information from your cache!',
    'loading': 'loading…',
    'Store': 'Store',
    'Unstore': 'Unstore',
    'Laminates.header': 'Welcome To MPT',
    'Laminates.header.offline': 'ATTENTION: You are offline right now. Please check your internet connection, you are currently only seeing information from your cache!',
    'Materials.header': 'Welcome To MPT',
    'Materials.header.offline': 'ATTENTION: You are offline right now. Please check your internet connection, you are currently only seeing information from your cache!',
    'Items in List': 'Items in List',
    'UNPAID': 'UNPAID',
    'Paid': 'Paid',
    'Order was paid on:': 'Order was paid on:',
    'orderstatus.complete': 'Complete',
    'Order was sent on:': 'Order was sent on:',
    'orderstatus.pending': 'Pending',
    'Planned delivery is on:': 'Planned delivery is on:',
    'orderstatus.waiting': 'Waiting',
    'We are waiting for Client feedback': 'We are waiting for Client feedback',
    'orderstatus.delayed': 'Delayed',
    'Order should have been delivered on:': 'Order should have been delivered on:',
    'orderstatus.broken': 'Broken',
    'Something needs to be reproduced because it was broken on:': 'Something needs to be reproduced because it was broken on:',
    'Usually this means delivery will be delayed for 3 working days after it was broken.': 'Usually this means delivery will be delayed for 3 working days after it was broken.',
    'back to list': 'back to list',
    'PremadeFiles List': 'PremadeFiles List',
    'refresh list': 'refresh list',
    'new': 'new',
    'last update': 'last update',
    'edit': 'edit',
    'max width': 'max width',
    'max length': 'max length',
    'Generate Compilation': 'Generate Compilation',
    'ShippingStatisticDashboard.header.offline': 'ATTENTION: You are offline right now. Please check your internet connection, you are currently only seeing information from your cache!',
    'StatisticDashboard.header.offline': 'ATTENTION: You are offline right now. Please check your internet connection, you are currently only seeing information from your cache!',
    'by scope': 'by scope',
    'digital print': 'digital print',
    'automated plott': 'automated plott',
    'manual plott': 'manual plott',
    'multi color plott': 'multi color plott',
    'blank_film_cut': 'Blank Film Cut',
    'by status': 'by status',
    'file_ready': 'file_ready',
    'supplier': 'supplier',
    'printing': 'printing',
    'printed': 'printed',
    'plotted': 'plotted',
    'laminated': 'laminated',
    'at_weeding': 'at_weeding',
    'weeded': 'weeded',
    'finishing': 'finishing',
    'qualitycheck': 'qualitycheck',
    'information_requested': 'information_requested',
    'no filecheck': 'no filecheck',
    'with filecheck': 'with filecheck',
    'production data': 'production data',
    'design service': 'design service',
  },
  'it': {
    'Name': '',
    'Description': '',
    'Submit': 'Invia',
    'Summary': '',
    'Full History': '',
    'Raw Object': '',
    'Change Item Settings': '',
    'Preview': '',
    'series': '',
    'producer': '',
    'title': '',
    'key': '',
    'Email': 'Email',
    'Password': 'Password',
    'ForgotPassword': 'Dimenticato le credenziali? Clicca',
    'ForgotPassword.here': 'qui',
    'roll_width': '',
    'color_hex': '',
    'color_code': '',
    'description': '',
    'subtitle': '',
    'material_group': '',
    'NewPassword': 'Nuova Password',
    'NewPasswordConfirmation': 'Conferma Nuova Password',
    'PasswordConfirmation': 'Conferma Password',
    'Number': '',
    'Text': '',
    'TextArea': '',
    'checkbox': '',
    'Switch': '',
    'Male': '',
    'Female': '',
    'RadioGroup': '',
    'select': '',
    'multi-select': '',
    'Home.header': 'Ciao MPT',
    'Home.header.offline': 'Ciao offline MPT',
    'loading': '',
    'Laminates.header': 'Ciao MPT',
    'Laminates.header.offline': 'Ciao offline MPT',
    'Materials.header': 'Ciao MPT',
    'Materials.header.offline': 'Ciao offline MPT',
  },
}
