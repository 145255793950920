import { createAction } from 'redux-actions';
import notificationsActions from '../notifications'
/**
 * The list of action types for CRUD operations on the resource
 */
const GET_SHIPPING_STATISTICS = 'statistics/GET_SHIPPING_STATISTICS';
const GET_SHIPPING_STATISTICS_SUCCESS = 'statistics/GET_SHIPPING_STATISTICS_SUCCESS';
const GET_SHIPPING_STATISTICS_FAIL = 'statistics/GET_SHIPPING_STATISTICS_FAIL';
const LIST = 'statistics/LIST';
const LIST_SUCCESS = 'statistics/LIST_SUCCESS';
const LIST_ALL_SUCCESS = 'statistics/LIST_ALL_SUCCESS';
const LIST_FAIL = 'statistics/LIST_FAIL';
const SHOW = 'statistics/SHOW';
const SHOW_SUCCESS = 'statistics/SHOW_SUCCESS';
const SHOW_FAIL = 'statistics/SHOW_FAIL';
const CREATE = 'statistics/CREATE';
const CREATE_SUCCESS = 'statistics/CREATE_SUCCESS';
const CREATE_FAIL = 'statistics/CREATE_FAIL';
const UPDATE = 'statistics/UPDATE';
const UPDATE_SUCCESS = 'statistics/UPDATE_SUCCESS';
const UPDATE_FAIL = 'statistics/UPDATE_FAIL';
const REMOVE = 'statistics/REMOVE';
const REMOVE_SUCCESS = 'statistics/REMOVE_SUCCESS';
const REMOVE_FAIL = 'statistics/REMOVE_FAIL';
const STORE = 'statistics/STORE';
const UNSTORE = 'statistics/UNSTORE';

/**
 * list statistics actions
 */
const listSuccess = createAction(LIST_SUCCESS);
const listAllSuccess = createAction(LIST_ALL_SUCCESS);
const listFail = createAction(LIST_FAIL);
const list = createAction(LIST);

/**
 * show statistic actions
 */
const showSuccess = createAction(SHOW_SUCCESS);
const showFail = createAction(SHOW_FAIL);
const show = createAction(SHOW);

/**
 * create statistic actions
 */
const createSuccess = createAction(CREATE_SUCCESS);
const createFail = createAction(CREATE_FAIL);
const create = createAction(CREATE);

/**
 * update statistic actions
 */
const updateSuccess = createAction(UPDATE_SUCCESS);
const updateFail = createAction(UPDATE_FAIL);
const update = createAction(UPDATE);

/**
 * update statistic actions
 */
const getShippingStatisticsSuccess = createAction(GET_SHIPPING_STATISTICS_SUCCESS);
const getShippingStatisticsFail = createAction(GET_SHIPPING_STATISTICS_FAIL);
const getShippingStatistics = createAction(GET_SHIPPING_STATISTICS);

/**
 * remove statistic actions
 */
const removeSuccess = createAction(REMOVE_SUCCESS);
const removeFail = createAction(REMOVE_FAIL);
const remove = createAction(REMOVE);

/**
 * store statistics actions
 * it also dispatches a notification with the amount of storage used
 */
const storeStatistic = createAction(STORE,
  () => async (dispatch) => {
    if (navigator && 'storage' in navigator && 'estimate' in navigator.storage) {
      navigator.storage.estimate()
      .then(function(estimate){
        const {usage, quota} = estimate
        const percentUsed = Math.round(usage / quota * 100);
        const usageInMib = Math.round(usage / (1024 * 1024));
        const quotaInMib = Math.round(quota / (1024 * 1024));
        const message = `${usageInMib} out of ${quotaInMib} MiB used (${percentUsed}%)`;
        const color = percentUsed > 80 ? 'danger' : 'success'
        let alertOptions = { message, color, origin: 'storage', timeout: 5 }
        dispatch(notificationsActions.showAlert(alertOptions))
      });
    }
  }
);
const unstoreStatistic = createAction(UNSTORE);


/**
 * exported actions
 */
export default {
  list: list,
  listSuccess: listSuccess,
  listAllSuccess: listAllSuccess,
  listFail: listFail,
  getShippingStatistics: getShippingStatistics,
  getShippingStatisticsSuccess: getShippingStatisticsSuccess,
  getShippingStatisticsFail: getShippingStatisticsFail,
  show: show,
  showSuccess: showSuccess,
  showFail: showFail,
  create: create,
  createSuccess: createSuccess,
  createFail: createFail,
  update: update,
  updateSuccess: updateSuccess,
  updateFail: updateFail,
  remove: remove,
  removeSuccess: removeSuccess,
  removeFail: removeFail,
  storeStatistic: storeStatistic,
  unstoreStatistic: unstoreStatistic
}
