/**
 *  Sagas for the compilation resource managment using Swagger client
 * 
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 * Fetch calls are made via the swagger tags interface requests
 */
import { call, put, takeEvery, select } from 'redux-saga/effects'
import buildHeaders from '../../utils/buildHeaders';
import actions from './compilations-actions';
import Swagger from 'swagger-client';
import {isServer} from "../../utils/store";

const API_URL = process.env.REACT_APP_API_SERVER
/**
 * The swagger client. We make this call only once to load the swagger json
 */
const SwaggerApiRequest = Swagger({ url: `${API_URL}/api-docs/v1/swagger.json` });

/**
 * Request interceptor used to add headers to the request
 * @param {Object} headers the headers to apply
 * @returns a request object
 */
const applyHeaders = (headers) => (req) => {
  if(headers){
    Object.keys(headers).map(headerKey => 
      req.headers[headerKey] = headers[headerKey]
    )
  }
  return req;
}

/** Worker Sagas */

/** List Saga
 *  @description: connects to the getCompilations operation
 */
export function* list(action) {
  var loadItemsFilters = false;
  if (action.payload && action.payload.loadItemsFilters) loadItemsFilters = true
  const currentUser = yield select(state => state.account.currentUser);
  let headers = buildHeaders(currentUser)
  try {
    let options = {}
    if(loadItemsFilters)
      options = yield select(state => state.items.filters); // Use Item Filters to return the right compilations
    else
      // options = {compilation_status: 'new'}
      options = yield select(state => state.compilations.filters);
    const payload = yield call(SwaggerApiRequest.client.apis.Compilations.getCompilations, options, {requestInterceptor: applyHeaders(headers)})
    yield put({type: actions.listSuccess, payload: payload.obj});
  } catch (e) {
    yield put({type: actions.listFail, payload: e});
  }
}

/** Show Saga
 *  @description: connects to the showCompilation operation
 *  @param {number} action.payload the compilation id
 */
export function* show(action) {
  const compilationId = action.payload;
  const storedCompilations = yield select(state => state.compilations.stored);
  /** if we are offline we use persisted data */
  if (!isServer && (navigator && !navigator.onLine) && storedCompilations[compilationId]) {
    yield put({
      type: actions.showSuccess,
      payload: storedCompilations[compilationId]
    });
  } else { // else we are online -> we fetch
    const currentUser = yield select(state => state.account.currentUser);
    let headers = buildHeaders(currentUser)
    try {
      const payload = yield call(SwaggerApiRequest.client.apis.Compilations.showCompilation, {id: compilationId}, {requestInterceptor: applyHeaders(headers)})
      yield put({type: actions.showSuccess, payload: payload.obj});
    } catch (e) {
      yield put({type: actions.showFail, payload: e});
    }
  }
}

/** Create Saga
 *  @description: connects to the createCompilation operation. If compilation contains a picture also connects to the uploadCompilation operation
 *  @param {Object} action.payload the compilation to create
 */
export function* create(action) {
  console.log("action")
  const currentUser = yield select(state => state.account.currentUser);
  let headers = buildHeaders(currentUser)
  let values = {...action.payload};
  try {
    console.log("try", values);
    let payload = yield call(SwaggerApiRequest.client.apis.Compilations.createCompilation, {compilation: values}, {requestInterceptor: applyHeaders(headers)});
    yield put({type: actions.createSuccess, payload: payload});
  } catch (e) {
    console.log("error", e);
    yield put({type: actions.createFail, payload: e});
  }
 }

/** Update Saga
 *  @description: connects to the updateCompilation operation. If compilation contains a picture also connects to the uploadCompilation operation
 *  @param {Object} action.payload the compilation to update
 */
export function* update(action) {
  const currentUser = yield select(state => state.account.currentUser);
  let headers = buildHeaders(currentUser)
  let values = action.payload;
  try {
    let payload = yield call(SwaggerApiRequest.client.apis.Compilations.updateCompilation, {id: values.id, compilation: values}, {requestInterceptor: applyHeaders(headers)});
    yield put({type: actions.updateSuccess, payload: payload.obj});
  } catch (e) {
    yield put({type: actions.updateFail, payload: e});
  }
 }

/** Remove Saga
 *  @description: connects to the deleteCompilation operation.
 *  @param {Object} action.payload the id of the compilation to delete
 */
export function* remove(action) {
  const currentUser = yield select(state => state.account.currentUser);
  let headers = buildHeaders(currentUser)
  try {
    let payload = yield call(SwaggerApiRequest.client.apis.Compilations.deleteCompilation, {id: action.payload.id}, {requestInterceptor: applyHeaders(headers)});
    yield put({type: actions.removeSuccess, payload: payload});
  } catch (e) {
    yield put({type: actions.removeFail, payload: e});
  }
}

/** Unstore Saga
 *  @description: handles the cache cleaning when an compilation is unstored
 */
export function* unstoreCompilation() {
  const image = yield select(state => state.compilations.show.image);
  caches.open('images-cache')
  .then(imagesCache => {
    console.log(imagesCache)
    return imagesCache.delete(image)
  })
  .then(response => {
    console.log(response)
  })
  .catch(err => {
    console.log('Could not clear cache', err);
  })
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched 
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* compilationsSaga() {
  yield takeEvery(actions.list, list);
  yield takeEvery(actions.show, show);
  yield takeEvery(actions.create, create);
  yield takeEvery(actions.update, update);
  yield takeEvery(actions.remove, remove);
  yield takeEvery(actions.unstoreCompilation, unstoreCompilation);
}
export default compilationsSaga;
