import { call, put, takeEvery, select } from 'redux-saga/effects'
import buildHeaders from '../../utils/buildHeaders';
import actions from './zendeskTickets-actions';
import Swagger from 'swagger-client';
import {isServer} from "../../utils/store";

const API_URL = process.env.REACT_APP_API_SERVER

const SwaggerApiRequest = Swagger({ url: `${API_URL}/api-docs/v1/swagger.json` });

const applyHeaders = (headers) => (req) => {
  if(headers){
    Object.keys(headers).map(headerKey => 
      req.headers[headerKey] = headers[headerKey]
    )
  }
  return req;
}

// worker Sagas
export function* list(action) {
  // if we are offline we use persisted data
  if ( !isServer && navigator && !navigator.onLine) { 
    let storedList = []
    const storedZendeskTickets = yield select(state => state.zendeskTickets.stored);
    Object.keys(storedZendeskTickets).forEach(storedZendeskTicketIndex => {
      storedList.push(Object.assign({}, storedZendeskTickets[storedZendeskTicketIndex]))
    })
    yield put({ type: actions.listSuccess, payload: storedList });
  } else { // else we are online -> we fetch
    const currentUser = yield select(state => state.account.currentUser);
    let headers = buildHeaders(currentUser)
    try {
      const options = yield select(state => state.items.filters); // Use Item Filters to return the right materials
      let zendeskOptions = { scope: options.scope, order_id: options.order_id }
      if (action && action.payload && action.payload.order_id){ zendeskOptions.order_id = action.payload.order_id; }
      const payload = yield call(SwaggerApiRequest.client.apis.ZendeskTickets.getZendeskTickets, zendeskOptions, {requestInterceptor: applyHeaders(headers)})
       yield put({type: actions.listSuccess, payload: payload.obj});
    } catch (e) {
      yield put({type: actions.listFail, payload: e});
    }
  }
}

export function* show(action) {
  const zendeskTicketId = action.payload;
  const storedZendeskTickets = yield select(state => state.zendeskTickets.stored);
  // if offline and id is stored
  if (!isServer && (navigator && !navigator.onLine) && storedZendeskTickets[zendeskTicketId]) {
    yield put({
      type: actions.showSuccess,
      payload: storedZendeskTickets[zendeskTicketId]
    });
  } else { // else we are online -> we fetch
    const currentUser = yield select(state => state.account.currentUser);
    let headers = buildHeaders(currentUser)
    try {
      const payload = yield call(SwaggerApiRequest.client.apis.ZendeskTickets.showZendeskTicket, {id: zendeskTicketId}, {requestInterceptor: applyHeaders(headers)})
      yield put({type: actions.showSuccess, payload: payload.obj});
    } catch (e) {
      yield put({type: actions.showFail, payload: e});
    }
  }
}

export function* create(action) {
  const currentUser = yield select(state => state.account.currentUser);
  let headers = buildHeaders(currentUser)
  let values = {...action.payload};
  delete values.picture;
  let uploadBody = new FormData();
  if (action.payload.picture) {
    uploadBody.append("picture", action.payload.picture[0], action.payload.picture[0].name); // add the file
  }

  try {
    let payload = yield call(SwaggerApiRequest.client.apis.ZendeskTickets.createZendeskTicket, {zendeskTicket: values}, {requestInterceptor: applyHeaders(headers)});
    if (action.payload.picture) {
      // headers["Content-Type"] = 'application/x-www-form-urlencoded '  // remove header
      uploadBody.append("id", payload.obj.id); // add the file
      uploadBody.append("zendeskTicket[id]", payload.obj.id); // add the file
      console.log('HEADERS', headers);
      console.log('payload', payload);
      payload = yield call(SwaggerApiRequest.client.apis.ZendeskTickets.uploadZendeskTicket, uploadBody, {requestInterceptor: applyHeaders(headers)})
    }
    yield put({type: actions.createSuccess, payload: payload});
  } catch (e) {
    yield put({type: actions.createFail, payload: e});
  }
 }

export function* update(action) {
  const currentUser = yield select(state => state.account.currentUser);
  let headers = buildHeaders(currentUser)
  let uploadBody = new FormData();
  let values = action.payload;
  delete values.user_id
  delete values.picture;
  if (action.payload.picture) {
    uploadBody.append("picture", action.payload.picture[0], action.payload.picture[0].name); // add the file
  }
  try {
    let payload = yield call(SwaggerApiRequest.client.apis.ZendeskTickets.updateZendeskTicket, {zendeskTicket: values}, {requestInterceptor: applyHeaders(headers)});
    if (action.payload.picture) {
      payload = yield call(SwaggerApiRequest.client.apis.ZendeskTickets.uploadZendeskTicket, {id: payload.obj.id, body: uploadBody}, {requestInterceptor: applyHeaders(headers)})
    }
    yield put({type: actions.updateSuccess, payload: payload});
  } catch (e) {
    yield put({type: actions.updateFail, payload: e});
  }
 }

 export function* remove(action) {
   const currentUser = yield select(state => state.account.currentUser);
   let headers = buildHeaders(currentUser)
  
  try {
    let payload = yield call(SwaggerApiRequest.client.apis.ZendeskTickets.deleteZendeskTicket, {id: action.payload.id}, {requestInterceptor: applyHeaders(headers)});
    yield put({type: actions.removeSuccess, payload: payload});
  } catch (e) {
    yield put({type: actions.removeFail, payload: e});
  }
}

export function* unstoreZendeskTicket(action) {
  const image = yield select(state => state.zendeskTickets.show.image);

  caches.open('images-cache')
  .then(imagesCache => {
    console.log(imagesCache)
    return imagesCache.delete(image)
  })
  .then(response => {
    console.log(response)
  })
  .catch(err => {
    console.log('Could not clear cache', err);
    
  })
}

/*
  Saga Watchers
*/
function* zendeskTicketsSaga() {
  yield takeEvery(actions.list, list);
  yield takeEvery(actions.show, show);
  yield takeEvery(actions.create, create);
  yield takeEvery(actions.update, update);
  yield takeEvery(actions.remove, remove);
  yield takeEvery(actions.unstoreZendeskTicket, unstoreZendeskTicket);
}

export default zendeskTicketsSaga;
