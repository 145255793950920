/** ----- apiRequest.js -----
 *  File: apiRequest.js
 *
 *  Purpose: providing helper functions to perform API requests to ease out
 *  error handling
 * ----------------------------- */
import Swagger from 'swagger-client'
/**
 * Wrapper for private API requests that provider additional error handling.
 */
const apiRequest = (_url, args) => {

  const request = {
    url: _url,
    method: args.method,
    body: args.body,
    headers: args.headers,
  }

  return Swagger.http(request)
  .then((res) => {
    return res.body;
  })
  .catch((err) => {
    console.log(err);
    throw err.response.obj;
  })
};

export default apiRequest;
