/**
 * The Can container
 */
import React from "react"; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import PropTypes from "prop-types";

/**
 * Can stateless component to check user has permission to render the children content
 * @param {*} props the user, the authorization list, the resource
 */
export function Can(props) {
  const {user, authorize, resource} = props;
  if (hasPermission(user, authorize, resource)) {
    return (props.children);
  } else return null;
}

/**
 * Selects props from the store and passes them to the component
 * @param {Object} state the store
 */
const mapStateToProps = state => ({
  user: state.account.currentUser
});

/** define proptypes for the component */
Can.propTypes = {
  user: PropTypes.object,
  authorize: PropTypes.object,
  resource: PropTypes.object
};

/** exports the connected component */
export default connect(
  mapStateToProps,
  null
)(Can);

/**
 * Function to check authorizations for a user
 * @param {Object} user 
 * @param {Object} authorizations 
 * @param {Object} resource 
 * @returns {boolean} the authorization state
 */
export function hasPermission(user, authorizations, resource) {
  var validToken = false;
  var isAuthorized = false;
  try {
    if (user && user.tokens) {
      validToken = new Date(parseInt(user.tokens.expiry)*1000) > new Date()
      if (user.permissions && user.permissions.can && user.permissions.can.manage && user.permissions.can.manage.all)
        return validToken
      if (user.permissions && user.permissions_list){
        Object.keys(authorizations).forEach(action => {
          console.log('looooog', resource, user.permissions, authorizations, action)
          /** updated to work with cancancan 3.0 */
          // if (!(user.permissions.can[action] && (user.permissions.can[action][authorizations[action]]) || user.permissions.can[action]['all'] ) ) {
            if ((user.permissions.can[action] && (user.permissions.can[action][authorizations[action]])  ) ) {
            isAuthorized = true;
          }
          user.permissions_list.forEach(permission => {
            if (resource && permission.actions.includes(action) && permission.subject.includes(authorizations[action]) && permission.conditions) {
              Object.keys(permission.conditions).forEach(condition_key => {
                if (resource[condition_key] !== permission.conditions[condition_key] ) {
                  isAuthorized = false;
                } else if ((resource[condition_key] === permission.conditions[condition_key]) && permission.inverted) {
                  isAuthorized = false;
                }
              })
            }
          })
        })
      }
      
    }
  } catch(err) {
    console.error("Permission Validation failed");
  }
  return validToken && isAuthorized;
}

export function canUpdateResourceAttribute(user, resourceName, attributeName) {
  var validToken = false;
  var isAuthorized = false;
  if (user && user.tokens && user.permissions_list) {
    validToken = new Date(parseInt(user.tokens.expiry)*1000) > new Date()
    isAuthorized = false;
    if (user.permissions && user.permissions.can && user.permissions.can.manage && user.permissions.can.manage.all)
      return validToken
    /** updated to work with cancancan 3.0 */
    if (user.permissions && user.permissions.can && user.permissions.can.update && user.permissions.can.update[resourceName] && user.permissions.can.update[resourceName].includes(attributeName))
      isAuthorized = true;
    // user.permissions_list.forEach(permission => {
    //   if (permission.actions.includes('update') && permission.subject.includes(resourceName) && permission.inverted){
    //     if (permission.conditions && permission.conditions.length && permission.conditions.includes(attributeName))
    //       isAuthorized = false;
    //     if(permission.conditions === attributeName )
    //       isAuthorized = false;
    //   }
    // })
  }
  return validToken && isAuthorized;
}

export function listUpdatableResourceAttribute(user, resourceName, resource) {
  var updatableAttributes = {};
  Object.keys(resource).forEach(resourceAttributeName => {
    updatableAttributes[resourceAttributeName] = canUpdateResourceAttribute(user, resourceName, resourceAttributeName)
  })
  return updatableAttributes;
}
