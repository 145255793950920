/** ----- apiSwaggerRequest.js -----
 *  File: apiSwaggerRequest.js
 *
 *  Purpose: providing helper functions to perform API requests to ease out
 *  error handling through swagger client
 *----------------------------- */
import Swagger from "swagger-client";
const dotenv = require("dotenv");
dotenv.config();
const API_URL = process.env.REACT_APP_REVIEW_API_SERVER;
/**
 * Wrapper for private API requests that provide additional error handling.
 */
const swaggerRequest = (args) => {
  return Swagger({
    url: `${API_URL}/api-docs/v1/swagger.json`,
  });
  //   requestInterceptor: (req) => {
  //     if (args.headers) {
  //       Object.keys(args.headers).map(
  //         (headerKey) => (req.headers[headerKey] = args.headers[headerKey])
  //       );
  //     }
  //   },
  // }).then((client) => {
  //   let swaggerResource = client.apis[args.resource];
  //   return swaggerResource[args.swaggerMethod](args.params)
  //     .then((resp) => {
  //       return resp.body;
  //     })
  //     .catch((err) => {
  //       console.log("error", err);
  //       throw err;
  //     });
  // });
};

/**
 * Request interceptor used to add headers to the request
 * @param {Object} headers the headers to apply
 * @returns a request object
 */
export const applyHeaders = (headers) => (req) => {
  if (headers) {
    Object.keys(headers).map(
      (headerKey) => (req.headers[headerKey] = headers[headerKey])
    );
  }
  return req;
};

export default swaggerRequest;
