/**
 * Sagas for the notifications managment
 * 
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 */
import { delay, put, takeEvery } from 'redux-saga/effects'
import actions from './notifications-actions';

/** Worker Sagas */

/** Show alert saga
 * @param {Object} action.payload the alert timeout
 */
export function* showAlert(action) {
  if (action.payload && action.payload.timeout && action.payload.timeout > 0) {
    try {
      yield delay(action.payload.timeout * 1000)
      yield put({type: actions.hideAlert});
    } catch (e) {
      console.log('Failed to hide Alert');
    }
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched 
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* notificationsSaga() {
  yield takeEvery(actions.showAlert, showAlert);
}

export default notificationsSaga;
