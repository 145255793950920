import { handleActions } from 'redux-actions';
import actions from './statistics-actions';

/** The statistic reducer initial state */
export const defaultState = {
  stored: {},
  list: {},
  shippingStatistics: {},
  listAll: [],
  loadingList: false,
  loadedList: false,
  listError: null,
  show: null,
  loadingShow: false,
  loadedShow: false,
  showError: null,
  creating: false,
  created: false,
  createError: null,
  updating: false,
  updated: false,
  updateError: null,
  removing: false,
  removed: false,
  removeError: null
};

/** List of actions handled by reducer */
export default handleActions(
  {
    [actions.list]: (state) => {
      return {
        ...state,
        loadingList: true,
        loadedList: false,
        listError: null,
      };
    },
    [actions.listSuccess]: (state, action) => {
      return {
        ...state,
        loadingList: false,
        loadedList: true,
        list: action.payload
      };
    },
    [actions.getShippingStatistics]: (state) => {
      return {
        ...state,
        loadingShippingStats: true,
        loadedShippingStats: false,
        shippingStatsError: null,
      };
    },
    [actions.getShippingStatisticsSuccess]: (state, action) => {
      return {
        ...state,
        loadingShippingStats: false,
        loadedShippingStats: true,
        shippingStatistics: action.payload
      };
    },
    [actions.getShippingStatisticsFail]: (state, action) => {
      return {
        ...state,
        loadingShippingStats: false,
        loadedShippingStats: false,
        shippingStatsError: action.payload.mess
      };
    },
    [actions.listAllSuccess]: (state, action) => {
      return {
        ...state,
        loadingList: false,
        loadedList: true,
        listAll: action.payload
      };
    },
    [actions.listFail]: (state, action) => {
      return {
        ...state,
        loadingList: false,
        loadedList: false,
        listError: action.payload.mess
      };
    },
    [actions.show]: (state) => {
      return {
        ...state,
        loadingShow: true,
        loadedShow: false,
        showError: null,
      };
    },
    [actions.showSuccess]: (state, action) => {
      if (state.stored[action.payload.id]) {
        action.payload.stored = true
      }
      return {
        ...state,
        loadingShow: false,
        loadedShow: true,
        show: action.payload
      };
    },
    [actions.showFail]: (state, action) => {
      return {
        ...state,
        loadingShow: false,
        loadedShow: false,
        showError: action.payload.mess
      };
    },
    [actions.create]: (state) => {
      return {
        ...state,
        creating: true,
        created: false,
        createError: null,
      };
    },
    [actions.createSuccess]: (state, action) => {
      var newList = state.list.push(action.payload)
      return {
        ...state,
        list: newList,
        creating: false,
        created: true,
        show: action.payload
      };
    },
    [actions.createFail]: (state, action) => {
      return {
        ...state,
        creating: false,
        created: false,
        createError: action.payload.mess
      };
    },
    [actions.update]: (state) => {
      return {
        ...state,
        updating: true,
        updated: false,
        updateError: null,
      };
    },
    [actions.updateSuccess]: (state, action) => {
      return {
        ...state,
        updating: false,
        updated: true,
        show: action.payload
      };
    },
    [actions.updateFail]: (state, action) => {
      return {
        ...state,
        updating: false,
        updated: false,
        updateError: action.payload.mess
      };
    },
    [actions.remove]: (state) => {
      return {
        ...state,
        removing: true,
        removed: false,
        removeError: null,
      };
    },
    [actions.removeSuccess]: (state, action) => {
      return {
        ...state,
        removing: false,
        removed: true,
        show: action.payload
      };
    },
    [actions.removeFail]: (state, action) => {
      return {
        ...state,
        removing: false,
        removed: false,
        removeError: action.payload.mess
      };
    },
    [actions.storeStatistic]: (state) => {
      let newStoredStatistics = Object.assign({}, state.stored)
      newStoredStatistics[state.show.id] = state.show;
      let newShow = Object.assign({}, state.show);
      newShow.stored = true;
      return {
        ...state,
        stored: newStoredStatistics,
        show: newShow
      };
    },
    [actions.unstoreStatistic]: (state, action) => {
      let newStoredStatistics = Object.assign({}, state.stored)
      delete newStoredStatistics[action.payload];
      let newShow = Object.assign({}, state.show);
      newShow.stored = false;
      return {
        ...state,
        stored: newStoredStatistics,
        show: newShow
      };
    }
  },
  defaultState
);
