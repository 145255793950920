
/**
 * The root reducer function. This function is used by the store to mutate its state. All reducers should subscirbe in this file in order to have effect
 */
import { combineReducers } from "redux";
import account from "./account/account-reducers";
import { connectRouter } from 'connected-react-router';
import {i18nState} from "redux-i18n";
import { reducer as formReducer } from 'redux-form'
import servWork from "./servWork";
import notifications from "./notifications/notifications-reducers";
import items from "./items/items-reducers";
import itemParts from "./itemParts/itemParts-reducers";
// DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
// new scaffolded imports
import materials from './materials/materials-reducers';
import laminates from './laminates/laminates-reducers';
import statistics from './statistics/statistics-reducers';
import compilations from './compilations/compilations-reducers';
import premadeFiles from './premadeFiles/premadeFiles-reducers';
import zendeskTickets from './zendeskTickets/zendeskTickets-reducers';
import orderReviews from './cop/order_reviews';
import lineItemProblems from './cop/line_item_problem'
import lineItemFeedbacks from './cop/line_item_feedback';
import lineItemProblemSolutions from './cop/line_item_problem_solution';
import messages from './cop/message';

/** exports all reducers through the combineReducers function */
export default (history) => combineReducers({
  router: connectRouter(history),
  i18nState,
  account,
  servWork,
  notifications,
  items,
  itemParts,
  // DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
  // new scaffolded reducers
  materials, 
  laminates,  
  statistics,  
  compilations,
  premadeFiles,
  zendeskTickets,
  orderReviews,
  lineItemProblems,
  lineItemFeedbacks,
  lineItemProblemSolutions,
  messages,
  form: formReducer
});
