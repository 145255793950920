import { call, put, takeEvery, select } from 'redux-saga/effects'
import buildHeaders from '../../utils/buildHeaders';
import actions from './laminates-actions';
import Swagger from 'swagger-client';
import {isServer} from "../../utils/store";

const API_URL = process.env.REACT_APP_API_SERVER

const SwaggerApiRequest = Swagger({ url: `${API_URL}/api-docs/v1/swagger.json` });

const applyHeaders = (headers) => (req) => {
  if(headers){
    Object.keys(headers).map(headerKey => 
      req.headers[headerKey] = headers[headerKey]
    )
  }
  return req;
}

// worker Sagas
export function* list(action) {
  // if we are offline we use persisted data
  if ( !isServer && navigator && !navigator.onLine) { 
    let storedList = []
    const storedLaminates = yield select(state => state.laminates.stored);
    Object.keys(storedLaminates).forEach(storedLaminateIndex => {
      storedList.push(Object.assign({}, storedLaminates[storedLaminateIndex]))
    })
    yield put({ type: actions.listSuccess, payload: storedList });
  } else { // else we are online -> we fetch
    const currentUser = yield select(state => state.account.currentUser);
    let headers = buildHeaders(currentUser)
    try {
      let options = yield select(state => state.items.filters); // Use Item Filters to return the right materials
      if (action && action.payload && action.payload.listAll){ options = {}}
      const payload = yield call(SwaggerApiRequest.client.apis.Laminates.getLaminates, options, {requestInterceptor: applyHeaders(headers)})
      if (action && action.payload && action.payload.listAll){
        yield put({type: actions.listAllSuccess, payload: payload.obj})
      } else {
        yield put({type: actions.listSuccess, payload: payload.obj});
      }
    } catch (e) {
      yield put({type: actions.listFail, payload: e});
    }
  }
}

export function* show(action) {
  const laminateId = action.payload;
  const storedLaminates = yield select(state => state.laminates.stored);
  // if offline and id is stored
  if (!isServer && (navigator && !navigator.onLine) && storedLaminates[laminateId]) {
    yield put({
      type: actions.showSuccess,
      payload: storedLaminates[laminateId]
    });
  } else { // else we are online -> we fetch
    const currentUser = yield select(state => state.account.currentUser);
    let headers = buildHeaders(currentUser)
    try {
      const payload = yield call(SwaggerApiRequest.client.apis.Laminates.showLaminate, {id: laminateId}, {requestInterceptor: applyHeaders(headers)})
      yield put({type: actions.showSuccess, payload: payload.obj});
    } catch (e) {
      yield put({type: actions.showFail, payload: e});
    }
  }
}

export function* create(action) {
  const currentUser = yield select(state => state.account.currentUser);
  let headers = buildHeaders(currentUser)
  let values = {...action.payload};
  delete values.picture;
  let uploadBody = new FormData();
  if (action.payload.picture) {
    uploadBody.append("picture", action.payload.picture[0], action.payload.picture[0].name); // add the file
  }

  try {
    let payload = yield call(SwaggerApiRequest.client.apis.Laminates.createLaminate, {laminate: values}, {requestInterceptor: applyHeaders(headers)});
    if (action.payload.picture) {
      // headers["Content-Type"] = 'application/x-www-form-urlencoded '  // remove header
      uploadBody.append("id", payload.obj.id); // add the file
      uploadBody.append("laminate[id]", payload.obj.id); // add the file
      console.log('HEADERS', headers);
      console.log('payload', payload);
      payload = yield call(SwaggerApiRequest.client.apis.Laminates.uploadLaminate, uploadBody, {requestInterceptor: applyHeaders(headers)})
    }
    yield put({type: actions.createSuccess, payload: payload});
  } catch (e) {
    yield put({type: actions.createFail, payload: e});
  }
 }

export function* update(action) {
  const currentUser = yield select(state => state.account.currentUser);
  let headers = buildHeaders(currentUser)
  let uploadBody = new FormData();
  let values = action.payload;
  delete values.user_id
  delete values.picture;
  if (action.payload.picture) {
    uploadBody.append("picture", action.payload.picture[0], action.payload.picture[0].name); // add the file
  }
  try {
    let payload = yield call(SwaggerApiRequest.client.apis.Laminates.updateLaminate, {laminate: values}, {requestInterceptor: applyHeaders(headers)});
    if (action.payload.picture) {
      payload = yield call(SwaggerApiRequest.client.apis.Laminates.uploadLaminate, {id: payload.obj.id, body: uploadBody}, {requestInterceptor: applyHeaders(headers)})
    }
    yield put({type: actions.updateSuccess, payload: payload});
  } catch (e) {
    yield put({type: actions.updateFail, payload: e});
  }
 }

 export function* remove(action) {
   const currentUser = yield select(state => state.account.currentUser);
   let headers = buildHeaders(currentUser)
  
  try {
    let payload = yield call(SwaggerApiRequest.client.apis.Laminates.deleteLaminate, {id: action.payload.id}, {requestInterceptor: applyHeaders(headers)});
    yield put({type: actions.removeSuccess, payload: payload});
  } catch (e) {
    yield put({type: actions.removeFail, payload: e});
  }
}

export function* unstoreLaminate(action) {
  const image = yield select(state => state.laminates.show.image);

  caches.open('images-cache')
  .then(imagesCache => {
    console.log(imagesCache)
    return imagesCache.delete(image)
  })
  .then(response => {
    console.log(response)
  })
  .catch(err => {
    console.log('Could not clear cache', err);
    
  })
}

/*
  Saga Watchers
*/
function* laminatesSaga() {
  yield takeEvery(actions.list, list);
  yield takeEvery(actions.show, show);
  yield takeEvery(actions.create, create);
  yield takeEvery(actions.update, update);
  yield takeEvery(actions.remove, remove);
  yield takeEvery(actions.unstoreLaminate, unstoreLaminate);
}

export default laminatesSaga;
