/**
 *  Sagas for the item resource managment using Swagger client
 * 
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 * Fetch calls are made via the swagger tags interface requests
 */
import { call, put, takeEvery, select, delay } from 'redux-saga/effects'
import buildHeaders from '../../utils/buildHeaders';
import actions from './itemParts-actions';
import itemActions from '../items';
import {list as listItems} from '../items/items-sagas';
import Swagger from 'swagger-client';
import {isServer} from "../../utils/store";
import notificationActions from '../notifications'

const API_URL = process.env.REACT_APP_API_SERVER
/**
 * The swagger client. We make this call only once to load the swagger json
 */
const SwaggerApiRequest = Swagger({ url: `${API_URL}/api-docs/v1/swagger.json` });

/**
 * Request interceptor used to add headers to the request
 * @param {Object} headers the headers to apply
 * @returns a request object
 */
const applyHeaders = (headers) => (req) => {
  if(headers){
    Object.keys(headers).map(headerKey => 
      req.headers[headerKey] = headers[headerKey]
    )
  }
  return req;
}

/** Worker Sagas */

/** List Saga
 *  @description: connects to the getItemParts operation
 */
export function* list() {
  /** if we are offline we use persisted data */
  if ( !isServer && navigator && !navigator.onLine) { 
    let storedList = []
    const storedItemParts = yield select(state => state.itemParts.stored);
    Object.keys(storedItemParts).forEach(storedItemPartIndex => {
      storedList.push(Object.assign({}, storedItemParts[storedItemPartIndex]))
    })
    yield put({ type: actions.listSuccess, payload: storedList });
  } else { 
    /**  else we are online -> we fetch */
    const currentUser = yield select(state => state.account.currentUser);
    let headers = buildHeaders(currentUser)
    try {
      const options = yield select(state => state.itemParts.filters);
      const payload = yield call(SwaggerApiRequest.client.apis.ItemParts.getItemParts, options, {requestInterceptor: applyHeaders(headers)})
      yield put({type: actions.listSuccess, payload: payload.obj});
    } catch (e) {
      yield put({type: actions.listFail, payload: e});
    }
  }
}

/** Show Saga
 *  @description: connects to the showItemPart operation
 *  @param {number} action.payload the item id
 */
export function* show(action) {
  const itemId = action.payload;
  const storedItemParts = yield select(state => state.itemParts.stored);
  /** if we are offline we use persisted data */
  if (!isServer && (navigator && !navigator.onLine) && storedItemParts[itemId]) {
    yield put({
      type: actions.showSuccess,
      payload: storedItemParts[itemId]
    });
  } else { // else we are online -> we fetch
    const currentUser = yield select(state => state.account.currentUser);
    let headers = buildHeaders(currentUser)
    try {
      const payload = yield call(SwaggerApiRequest.client.apis.ItemParts.showItemPart, {id: itemId}, {requestInterceptor: applyHeaders(headers)})
      yield put({type: actions.showSuccess, payload: payload.obj});
    } catch (e) {
      yield put({type: actions.showFail, payload: e});
    }
  }
}

/** Create Saga
 *  @description: connects to the createItemPart operation. If item contains a picture also connects to the uploadItemPart operation
 *  @param {Object} action.payload the item to create
 */
export function* create(action) {
  const currentUser = yield select(state => state.account.currentUser);
  let headers = buildHeaders(currentUser)
  let values = {...action.payload};
  try {
    let payload = yield call(SwaggerApiRequest.client.apis.ItemParts.createItemPart, {item_part: values}, {requestInterceptor: applyHeaders(headers)});
    yield put({type: actions.createSuccess, payload: payload});
  } catch (e) {
    yield put({type: actions.createFail, payload: e});
  }
 }

/** Update Saga
 *  @description: connects to the updateItemPart operation. If item contains a picture also connects to the uploadItemPart operation
 *  @param {Object} action.payload the item to update
 */
export function* update(action) {
  const currentUser = yield select(state => state.account.currentUser);
  let headers = buildHeaders(currentUser)
  try {
    let payload = yield call(SwaggerApiRequest.client.apis.ItemParts.updateItemParts, {id: action.payload.id, item_id: action.payload.item_id, item_part: action.payload}, {requestInterceptor: applyHeaders(headers)});
    yield put({type: actions.updateSuccess, payload: payload.obj});
    let alertOptions = { message: 'Item updated!', color: 'success', origin: 'sagas' }
    yield put({type: notificationActions.showAlert, payload: alertOptions})
    yield delay(5000);
    yield put({type: notificationActions.hideAlert})
    
  } catch (e) {
    yield put({type: actions.updateFail, payload: e});
    let alertOptions = { message: 'Error updating item!', color: 'danger', origin: 'sagas'}
    yield put({type: notificationActions.showAlert, payload: alertOptions})
    yield delay(5000);
    yield put({type: notificationActions.hideAlert})
  }
 }

 function* updateAndNext (action) {
  yield call(update, action)
  const itemFilters = yield select(state => state.items.filters);
  const newParams = {
    scope: itemFilters.scope || 'no_filecheck',
    status: itemFilters.status || 'new',
    limit: 1,
    not_connected: true
  }
  yield put({type: itemActions.filter, payload: newParams})
  const redirectToNext = true;
  action.payload = {redirectToNext}
  action.type = 'items/LIST'
  yield call(listItems, action)
}

/** Remove Saga
 *  @description: connects to the deleteItemPart operation.
 *  @param {Object} action.payload the id of the item to delete
 */
export function* remove(action) {
  const currentUser = yield select(state => state.account.currentUser);
  let headers = buildHeaders(currentUser)
  try {
    let payload = yield call(SwaggerApiRequest.client.apis.ItemParts.deleteItemParts, {id: action.payload.itemPartId, item_id: action.payload.itemId}, {requestInterceptor: applyHeaders(headers)});
    yield put({type: actions.removeSuccess, payload: payload.obj});
    console.log('success', payload)
  } catch (e) {
    yield put({type: actions.removeFail, payload: e});
  }
}

/** Unstore Saga
 *  @description: handles the cache cleaning when an item is unstored
 */
export function* unstoreItemPart() {
  const image = yield select(state => state.itemParts.show.image);
  caches.open('images-cache')
  .then(imagesCache => {
    console.log(imagesCache)
    return imagesCache.delete(image)
  })
  .then(response => {
    console.log(response)
  })
  .catch(err => {
    console.log('Could not clear cache', err);
  })
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched 
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */



function* itemPartsSaga() {
  yield takeEvery(actions.list, list);
  yield takeEvery(actions.show, show);
  yield takeEvery(actions.create, create);
  yield takeEvery(actions.update, update);
  yield takeEvery(actions.updateAndSkip, updateAndNext);
  yield takeEvery(actions.remove, remove);
  yield takeEvery(actions.unstoreItemPart, unstoreItemPart);
}
export default itemPartsSaga;
