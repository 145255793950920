/**
 * The root saga function. This function is used to register all sagas watching the store
 */
import serviceWorkerSaga from './servWork/servWork-sagas'
import accountSaga from './account/account-sagas'
import notificationsSaga from './notifications/notifications-sagas'
import itemsSaga from './items/items-sagas'
import itemPartsSaga from './itemParts/itemParts-sagas'
import { all } from 'redux-saga/effects'
// DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
// new scaffolded imports
import materialsSaga from './materials/materials-sagas'
import laminatesSaga from './laminates/laminates-sagas'
import statisticsSaga from './statistics/statistics-sagas'
import compilationsSaga from './compilations/compilations-sagas'
import premadeFilesSaga from './premadeFiles/premadeFiles-sagas'
import zendeskTicketsSaga from './zendeskTickets/zendeskTickets-sagas'
import orderReviewsSaga from './cop/order_reviews/sagas';
import lineItemFeedbacksSaga from './cop/line_item_feedback/sagas';

/** exports the rootSaga for the store */
export default function* rootSaga() {
  yield all([
    accountSaga(),
    itemsSaga(),
    itemPartsSaga(),
    serviceWorkerSaga(),
    notificationsSaga(),
    // DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
    // new scaffolded sagas
    materialsSaga(),
    laminatesSaga(),
    premadeFilesSaga(),
    statisticsSaga(),
    compilationsSaga(),
    zendeskTicketsSaga(),
    orderReviewsSaga(),
    lineItemFeedbacksSaga(),  
  ])
}
