/** 
 * The Sidebar component
 */
import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from 'reactstrap';
import ItemPreviewLabels from './utils/ItemPreviewLabels';
import Moment from 'react-moment';
import FormattedNumber from '../FormattedNumber';

/**
 * ItemPreview stateless component
 * - ItemPreview Component
 */
const ItemPreview = (props, context) => {
  const { item } = props;
  const language = 'de'; // only for formatted numbers
  return (
    <div className="itempreview__container container">
      <Row>
        <Col xs={8} className="small">
          <ItemPreviewLabels item={ item }/>
        </Col>
        <Col xs={4}>
          <ul className="itempreview__usedcolors">
            { item && item.item_parts && item.item_parts.map(item_part =>
              <li key={item_part.id}>
                { /* color.is_currently_in_plotting_compilation &&
                  <img className="property-icon" src={'/icons/status/timer-play-512px.svg'}  width="19px" height="19px"/>
                */ }
                {item_part.material &&
                  <span className="itempreview__usedcolors__hexcodepreview" style={{backgroundColor: item_part.material.color_hex}}></span>
                }
                {item_part.material &&
                  <span className="itempreview__usedcolors__colorcodelabel small">{item_part.material.key}</span>
                }
              </li>
            )}
          </ul>
        </Col>
        <Col xs={4}>
          <img className="itempreview__image" src={ item.preview.thumb } alt={item.sku} />
          { item.qty_ordered > 1 &&
            <strong className="itempreview__qtylabel">{item.qty_ordered}x</strong>
          }
          { item.in_compilation  &&
            <img className="itempreview__incompilation" src={'/icons/history/compilation.svg'} alt="in compilation" width="24px" height="24px" />
          }
        </Col>
        <Col xs={8}>
          <h5 className="itempreview__title h6">
            {item.title}
          </h5>
          <div className="text-truncate">
            <span className="small">{item.sku}</span>
          </div>
          <div className="small">  
            <img className="itempreview__propertyicon" src={'/icons/status/dimension-arrow-box-width-512px.svg'} alt="width" width="12px" height="12px"/>
            <FormattedNumber value={item.width} language={language} unit="mm" decimals={1} />&nbsp;
            <img className="itempreview__propertyicon" src={'/icons/status/dimension-arrow-box-height-512px.svg'} alt="height" width="12px" height="12px"/>
            <FormattedNumber value={item.height} language={language} unit="mm" decimals={1} />&nbsp;
            { item.box_nr &&
              <span> <strong>{context.t('Box:')}</strong> { item.box_nr }</span>
            }
          </div>
          
        </Col>
        <Col xs={12}>
          <div className="small">
            <span className="order_id_label">#{item.shop_order_id? item.shop_order_id : "00000000000"}</span>&nbsp;|&nbsp;
            {context.t('Delivery')}: <Moment format="DD.MM.YYYY">
              { (item.extended_delivery_date ? item.extended_delivery_date : item.planned_delivery_date) }
              </Moment>
            &nbsp;(
              <Moment 
              fromNow
              local
              >{ (item.extended_delivery_date ? item.extended_delivery_date : item.planned_delivery_date) }</Moment>)
              
          </div>
        </Col>
      </Row>
    </div>
  );
}

/** define proptypes for the form */
ItemPreview.propTypes = {
  item: PropTypes.object,
};

/**  define proptype for the 'translation' function  */
ItemPreview.contextTypes = {
  t: PropTypes.func
}

export default ItemPreview;
