/**
 * The Sidebar component
 */
import React from "react";
import PropTypes from "prop-types";
import { Badge } from "reactstrap";

/**
 * ItemPreview stateless component
 * - ItemPreview Component
 */
const ItemPreviewLabels = (props, context) => {
  const { item } = props;
  return (
    <div className="itempreview__labels">
      {item.priority >= 10 && (
        <Badge color="danger">{context.t("express")}</Badge>
      )}
      {item.priority > 0 && item.priority < 5 && (
        <Badge color="secondary">{context.t("medium")}</Badge>
      )}
      {item.priority >= 5 && item.priority <= 9 && (
        <Badge color="warning">{context.t("high")}</Badge>
      )}
      {item.extra.filecheck && (
        <Badge color="info">{context.t("filecheck")}</Badge>
      )}
      {item.extra.productiondata && (
        <Badge color="info">{context.t("productiondata")}</Badge>
      )}
      {item.extra.vectorize && (
        <Badge color="info">{context.t("vectorize")}</Badge>
      )}
      {item.is_underglass && <Badge color="light">{context.t("HGV")}</Badge>}
      {!item.paid_at && <Badge color="danger">⚠️ {context.t("Unpaid")}</Badge>}
      {item.is_amazon && <Badge color="warning">Amazon</Badge>}
      {(item.shop_order_id.substr(0, 1) === "P" ||
        item.shop_order_id.substr(0, 1) === "C") && (
        <Badge color="dark">{context.t("claim")}</Badge>
      )}
      {/*
        <Only if={currentUser.can_use_filecheck}>
          <Only if={item.filechecks.length > 5 && JSON.parse(item.filechecks)[0].notification_sent_at}>
            <span className="filecheck_label">
              <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 14 14" style={{ "enable-background": "new 0 0 14 14;"}} width="15px" height="15px">
                <g>
                  <path d="M7,9L5.268,7.484l-4.952,4.245C0.496,11.896,0.739,12,1.007,12h11.986    c0.267,0,0.509-0.104,0.688-0.271L8.732,7.484L7,9z" fill="#D80027"/>
                  <path d="M13.684,2.271C13.504,2.103,13.262,2,12.993,2H1.007C0.74,2,0.498,2.104,0.318,2.273L7,8    L13.684,2.271z" fill="#D80027"/>
                  <polygon points="0,2.878 0,11.186 4.833,7.079   " fill="#D80027"/>
                  <polygon points="9.167,7.079 14,11.186 14,2.875   " fill="#D80027"/>
                </g>
              </svg>
            </span>
          </Only>
        </Only>
      */}
    </div>
  );
};

/** define proptypes for the form */
ItemPreviewLabels.propTypes = {
  item: PropTypes.object,
};

/**  define proptype for the 'translation' function  */
ItemPreviewLabels.contextTypes = {
  t: PropTypes.func,
};

export default ItemPreviewLabels;
