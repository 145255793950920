/**
 * Sagas for the service worker managment
 * 
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 */
import { call, put, takeLatest, select } from 'redux-saga/effects'
import apiRequest from '../../utils/apiRequest';
import buildHeaders from '../../utils/buildHeaders';
import actions from './servWork-actions';
const API_URL = process.env.REACT_APP_API_SERVER

/** Worker Sagas */

/** Send push subscription saga. Used to store push subscription data in the api
 */
export function* sendPushSubscription() {
  const currentUser = yield select(state => state.account.currentUser);
  const servWorkState = yield select(state => state.servWork)
  const {pushSubscription, savedPushSubscription} = servWorkState
  // check if we sent it already
  if (!savedPushSubscription) {
    if (currentUser && currentUser.id && pushSubscription) {
      let headers = buildHeaders(currentUser)
      let body = JSON.stringify({user_id: currentUser.id, data: JSON.stringify(pushSubscription)})
      try {
        const payload = yield call(apiRequest, `${API_URL}/push_subscriptions`, {
            body,
            method: 'POST',
            headers
          });
        yield put({type: actions.sendPushSubscriptionSuccess, payload: payload});
      } catch (e) {
        yield put({type: actions.sendPushSubscriptionFail, payload: e});
      }
    } else {
      yield put({type: actions.sendPushSubscriptionFail, payload: 'No current user!!'});
    }
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched 
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* serviceWorkerSaga() {
  yield takeLatest(actions.sendPushSubscription, sendPushSubscription);
}

export default serviceWorkerSaga;
