/**
 *  Sagas for the premadeFile resource managment using Swagger client
 * 
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 * Fetch calls are made via the swagger tags interface requests
 */
import { call, put, takeEvery, select } from 'redux-saga/effects'
import buildHeaders from '../../utils/buildHeaders';
import actions from './premadeFiles-actions';
import Swagger from 'swagger-client';

const API_URL = process.env.REACT_APP_API_SERVER
/**
 * The swagger client. We make this call only once to load the swagger json
 */
const SwaggerApiRequest = Swagger({ url: `${API_URL}/api-docs/v1/swagger.json` });

/**
 * Request interceptor used to add headers to the request
 * @param {Object} headers the headers to apply
 * @returns a request object
 */
const applyHeaders = (headers) => (req) => {
  if(headers){
    Object.keys(headers).map(headerKey => 
      req.headers[headerKey] = headers[headerKey]
    )
  }
  return req;
}

/** Worker Sagas */

/** List Saga
 *  @description: connects to the getPremadeFiles operation
 */
export function* list(action) {
  console.log("list");
  const currentUser = yield select(state => state.account.currentUser);
  let headers = buildHeaders(currentUser)
  try {
    let options = {}
    // options = yield select(state => state.premadeFiles.filters);
    const payload = yield call(SwaggerApiRequest.client.apis.PremadeFiles.getPremadeFiles, options, {requestInterceptor: applyHeaders(headers)})
    yield put({type: actions.listSuccess, payload: payload.obj});
  } catch (e) {
    yield put({type: actions.listFail, payload: e});
  }
}

/** Show Saga
 *  @description: connects to the showPremadeFile operation
 *  @param {number} action.payload the premadeFile id
 */
export function* show(action) {
  const premadeFileId = action.payload;
  const currentUser = yield select(state => state.account.currentUser);
  let headers = buildHeaders(currentUser)
  try {
    const payload = yield call(SwaggerApiRequest.client.apis.PremadeFiles.showPremadeFile, {id: premadeFileId}, {requestInterceptor: applyHeaders(headers)})
    yield put({type: actions.showSuccess, payload: payload.obj});
  } catch (e) {
    yield put({type: actions.showFail, payload: e});
  }
}

/** Create Saga
 *  @description: connects to the createPremadeFile operation. If premadeFile contains a picture also connects to the uploadPremadeFile operation
 *  @param {Object} action.payload the premadeFile to create
 */
export function* create(action) {
  console.log("action")
  const currentUser = yield select(state => state.account.currentUser);
  let headers = buildHeaders(currentUser)
  let values = {...action.payload};
  try {
    console.log("try", values);
    let payload = yield call(SwaggerApiRequest.client.apis.PremadeFiles.createPremadeFile, { premade_file: values }, {requestInterceptor: applyHeaders(headers)});
    yield put({type: actions.createSuccess, payload: payload});
  } catch (e) {
    console.log("error", e);
    yield put({type: actions.createFail, payload: e});
  }
 }

/** Update Saga
 *  @description: connects to the updatePremadeFile operation. If premadeFile contains a picture also connects to the uploadPremadeFile operation
 *  @param {Object} action.payload the premadeFile to update
 */
export function* update(action) {
  const currentUser = yield select(state => state.account.currentUser);
  let headers = buildHeaders(currentUser)
  let values = {...action.payload};
  try {
    let payload = yield call(SwaggerApiRequest.client.apis.PremadeFiles.updatePremadeFile, {id: values.id, premade_file: values}, {requestInterceptor: applyHeaders(headers)});
    yield put({type: actions.updateSuccess, payload: payload.obj});
  } catch (e) {
    yield put({type: actions.updateFail, payload: e});
  }
 }

/** Remove Saga
 *  @description: connects to the deletePremadeFile operation.
 *  @param {Object} action.payload the id of the premadeFile to delete
 */
export function* remove(action) {
  const currentUser = yield select(state => state.account.currentUser);
  let headers = buildHeaders(currentUser)
  try {
    let payload = yield call(SwaggerApiRequest.client.apis.PremadeFiles.deletePremadeFile, {id: action.payload.id}, {requestInterceptor: applyHeaders(headers)});
    yield put({type: actions.removeSuccess, payload: payload});
  } catch (e) {
    yield put({type: actions.removeFail, payload: e});
  }
}

/** Unstore Saga
 *  @description: handles the cache cleaning when an premadeFile is unstored
 */
export function* unstorePremadeFile() {
  const image = yield select(state => state.premadeFiles.show.image);
  caches.open('images-cache')
  .then(imagesCache => {
    console.log(imagesCache)
    return imagesCache.delete(image)
  })
  .then(response => {
    console.log(response)
  })
  .catch(err => {
    console.log('Could not clear cache', err);
  })
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched 
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* premadeFilesSaga() {
  yield takeEvery(actions.list, list);
  yield takeEvery(actions.show, show);
  yield takeEvery(actions.create, create);
  yield takeEvery(actions.update, update);
  yield takeEvery(actions.remove, remove);
  yield takeEvery(actions.unstorePremadeFile, unstorePremadeFile);
}
export default premadeFilesSaga;
