/**
 *  Sagas for the item resource managment using Swagger client
 *
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 * Fetch calls are made via the swagger tags interface requests
 */
import { call, put, takeEvery, select, delay } from "redux-saga/effects";
import buildHeaders from "../../utils/buildHeaders";
import actions from "./items-actions";
import Swagger from "swagger-client";
import { isServer } from "../../utils/store";
import { push } from "connected-react-router";
import notificationActions from "../notifications";
const API_URL = process.env.REACT_APP_API_SERVER;
/**
 * The swagger client. We make this call only once to load the swagger json
 */
const SwaggerApiRequest = Swagger({
  url: `${API_URL}/api-docs/v1/swagger.json`,
});

/**
 * Helpers
 */
const attributeToField = {
  qty_ordered: "Amount Ordered",
  claim_reason: "Claim Reason",
  height: "Height",
  width: "Width",
  priority: "Priority",
  manual: "Force Manual Plott",
  is_surface: "Is Surface",
  is_underglass: "Is Underglass",
};

const renderServerError = (error) => {
  console.log("renderservererror");
  let message = [];
  console.log(error);
  Object.keys(error).forEach((attribute) => {
    if (attributeToField[attribute]) {
      message.push(
        `${attributeToField[attribute]}: ${error[attribute].join(", ")}`
      );
    }
  });
  return message;
};

/**
 * Request interceptor used to add headers to the request
 * @param {Object} headers the headers to apply
 * @returns a request object
 */
const applyHeaders = (headers) => (req) => {
  if (headers) {
    Object.keys(headers).map(
      (headerKey) => (req.headers[headerKey] = headers[headerKey])
    );
  }
  return req;
};

/** Worker Sagas */

/** List Saga
 *  @description: connects to the getItems operation
 */
export function* list(listOptions) {
  /** if we are offline we use persisted data */
  if (!isServer && navigator && !navigator.onLine) {
    let storedList = [];
    const storedItems = yield select((state) => state.items.stored);
    Object.keys(storedItems).forEach((storedItemIndex) => {
      storedList.push(Object.assign({}, storedItems[storedItemIndex]));
    });
    yield put({ type: actions.listSuccess, payload: storedList });
  } else {
    /**  else we are online -> we fetch */
    const currentUser = yield select((state) => state.account.currentUser);
    let headers = buildHeaders(currentUser);
    try {
      const options = yield select((state) => state.items.filters);
      if (!options.only_produceable) delete options.only_produceable;
      if (!options.include_amazon) delete options.include_amazon;
      if (!options.rectangular_cutting) delete options.rectangular_cutting;
      console.log("itemfilters options", options);
      const payload = yield call(
        SwaggerApiRequest.client.apis.Items.getItems,
        options,
        { requestInterceptor: applyHeaders(headers) }
      );
      yield put({ type: actions.listSuccess, payload: payload.obj });
      if (
        listOptions &&
        listOptions.payload &&
        listOptions.payload.redirectToNext
      ) {
        if (payload.obj && payload.obj.length) {
          const itemId = payload.obj[0].id;
          yield put(push(`/design/${itemId}`));
        } else {
          let alertOptions = {
            message: "No Items left for this scope, please change it.",
            color: "success",
            origin: "sagas",
          };
          yield put({
            type: notificationActions.showAlert,
            payload: alertOptions,
          });
          yield delay(5000);
          yield put({ type: notificationActions.hideAlert });
        }
      }
    } catch (e) {
      yield put({ type: actions.listFail, payload: e });
    }
  }
}

/** List Saga
 *  @description: connects to the getItems operation
 */
export function* search(action) {
  let searchQuery = { ...action.payload };
  const currentUser = yield select((state) => state.account.currentUser);
  let headers = buildHeaders(currentUser);
  try {
    const payload = yield call(
      SwaggerApiRequest.client.apis.Items.getItems,
      searchQuery,
      { requestInterceptor: applyHeaders(headers) }
    );
    yield put({ type: actions.searchSuccess, payload: payload.obj });
  } catch (e) {
    yield put({ type: actions.searchFail, payload: e });
  }
}

/** Skip Saga
 *  @description: connects to the skipItem operation
 *  @param {number} action.payload the item id
 */
export function* skip(action) {
  const itemId = action.payload;
  const currentUser = yield select((state) => state.account.currentUser);
  let headers = buildHeaders(currentUser);
  try {
    const payload = yield call(
      SwaggerApiRequest.client.apis.Items.skipItem,
      { id: itemId },
      { requestInterceptor: applyHeaders(headers) }
    );
    yield put({ type: actions.skipSuccess, payload: payload.obj });
  } catch (e) {
    yield put({ type: actions.skipFail, payload: e });
  }
}

/** Create Order Review Saga
 *  @description: connects to the skipItem operation
 *  @param {number} action.payload the item id
 */
export function* createOrderReview(action) {
  const { itemId, order_review, callbackFnc } = action.payload;
  const currentUser = yield select((state) => state.account.currentUser);
  let headers = buildHeaders(currentUser);
  try {
    const payload = yield call(
      SwaggerApiRequest.client.apis.Items.createOrderReview,
      { id: itemId, order_review: order_review },
      { requestInterceptor: applyHeaders(headers) }
    );
    if (callbackFnc) callbackFnc();
    yield put({ type: actions.createOrderReviewSuccess, payload: payload.obj });
  } catch (e) {
    yield put({ type: actions.createOrderReviewFail, payload: e });
  }
}

/** Skip Saga
 *  @description: connects to the skipItem operation
 *  @param {number} action.payload the item id
 */
export function* importFiles(action) {
  const itemId = action.payload;
  const currentUser = yield select((state) => state.account.currentUser);
  let headers = buildHeaders(currentUser);
  try {
    const payload = yield call(
      SwaggerApiRequest.client.apis.Items.importFilesForItem,
      { id: itemId },
      { requestInterceptor: applyHeaders(headers) }
    );
    yield put({ type: actions.importFilesSuccess, payload: payload.obj });
  } catch (e) {
    yield put({ type: actions.importFilesFail, payload: e });
  }
}

/** Skip Saga
 *  @description: connects to the skipItem operation
 *  @param {number} action.payload the item id
 */
export function* generateProductionFile(action) {
  const itemId = action.payload;
  const currentUser = yield select((state) => state.account.currentUser);
  let headers = buildHeaders(currentUser);
  // try {
    const payload = yield call(
      SwaggerApiRequest.client.apis.Items.generateProductionFileForItem,
      { id: itemId },
      { requestInterceptor: applyHeaders(headers) }
    );
    yield put({ type: actions.generateProductionFileSuccess, payload: payload.obj });
  // } catch (e) {
  //   yield put({ type: actions.generateProductionFileFail, payload: e });
  // }
}

/** Show Saga
 *  @description: connects to the showItem operation
 *  @param {number} action.payload the item id
 */
export function* show(action) {
  const itemId = action.payload;
  const currentUser = yield select((state) => state.account.currentUser);
  let headers = buildHeaders(currentUser);
  try {
    const payload = yield call(
      SwaggerApiRequest.client.apis.Items.showItem,
      { id: itemId },
      { requestInterceptor: applyHeaders(headers) }
    );
    yield put({ type: actions.showSuccess, payload: payload.obj });
  } catch (e) {
    yield put({ type: actions.showFail, payload: e });
  }
}

/** Create Saga
 *  @description: connects to the createItem operation. If item contains a picture also connects to the uploadItem operation
 *  @param {Object} action.payload the item to create
 */
export function* create(action) {
  const currentUser = yield select((state) => state.account.currentUser);
  let headers = buildHeaders(currentUser);
  let values = { ...action.payload };
  delete values.picture;
  let uploadBody = new FormData();
  if (action.payload.picture) {
    uploadBody.append(
      "picture",
      action.payload.picture[0],
      action.payload.picture[0].name
    ); // add the file
  }
  try {
    let payload = yield call(
      SwaggerApiRequest.client.apis.Items.createItem,
      { item: values },
      { requestInterceptor: applyHeaders(headers) }
    );
    if (action.payload.picture) {
      uploadBody.append("id", payload.obj.id); // add the file
      uploadBody.append("item[id]", payload.obj.id); // add the file
      payload = yield call(
        SwaggerApiRequest.client.apis.Items.uploadItem,
        uploadBody,
        { requestInterceptor: applyHeaders(headers) }
      );
    }
    yield put({ type: actions.createSuccess, payload: payload });
  } catch (e) {
    yield put({ type: actions.createFail, payload: e });
  }
}

/** Update Saga
 *  @description: connects to the updateItem operation. If item contains a picture also connects to the uploadItem operation
 *  @param {Object} action.payload the item to update
 */
export function* update(action) {
  const currentUser = yield select((state) => state.account.currentUser);
  let item = Object.assign(action.payload);
  let headers = buildHeaders(currentUser);
  try {
    let payload = yield call(
      SwaggerApiRequest.client.apis.Items.updateItem,
      { id: action.payload.id, item: item },
      { requestInterceptor: applyHeaders(headers) }
    );
    yield put({ type: actions.updateSuccess, payload: payload.obj });
    let alertOptions = {
      message: "Item updated!",
      color: "success",
      origin: "sagas",
    };
    yield put({ type: notificationActions.showAlert, payload: alertOptions });
    yield delay(5000);
    yield put({ type: notificationActions.hideAlert });
  } catch (updateError) {
    let alertOptions = {
      message: "Error updating item!",
      color: "danger",
      origin: "sagas",
    };
    if (updateError.status === 422) {
      yield put({ type: actions.updateFail, payload: updateError.response });
      alertOptions.message += " " + renderServerError(updateError.response.obj);
    } else {
      yield put({ type: actions.updateFail, payload: updateError });
      alertOptions.message += " " + renderServerError(updateError);
    }
    console.log("aaaaaaaaaaaaa", alertOptions);
    yield put({ type: notificationActions.showAlert, payload: alertOptions });
    yield delay(5000);
    yield put({ type: notificationActions.hideAlert });
  }
}

/** Remove Saga
 *  @description: connects to the deleteItem operation.
 *  @param {Object} action.payload the id of the item to delete
 */
export function* remove(action) {
  const currentUser = yield select((state) => state.account.currentUser);
  let headers = buildHeaders(currentUser);
  try {
    let payload = yield call(
      SwaggerApiRequest.client.apis.Items.deleteItem,
      { id: action.payload.id },
      { requestInterceptor: applyHeaders(headers) }
    );
    yield put({ type: actions.removeSuccess, payload: payload });
  } catch (e) {
    yield put({ type: actions.removeFail, payload: e });
  }
}

/** Unstore Saga
 *  @description: handles the cache cleaning when an item is unstored
 */
export function* unstoreItem() {
  const image = yield select((state) => state.items.show.image);
  caches
    .open("images-cache")
    .then((imagesCache) => {
      console.log(imagesCache);
      return imagesCache.delete(image);
    })
    .then((response) => {
      console.log(response);
    })
    .catch((err) => {
      console.log("Could not clear cache", err);
    });
}

function* skipAndList(action) {
  yield call(skip, action);
  const itemFilters = yield select((state) => state.items.filters);
  const newParams = {
    scope: itemFilters.scope || "no_filecheck",
    status: itemFilters.status || "new",
    limit: 1,
    not_connected: true,
    manual_filecheck_completed_mode:
      itemFilters.manual_filecheck_completed_mode || "0",
  };
  yield put({ type: actions.filter, payload: newParams });
  const redirectToNext = true;
  action.payload = { redirectToNext };
  action.type = "items/LIST";
  yield call(list, action);
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* itemsSaga() {
  yield takeEvery(actions.list, list);
  yield takeEvery(actions.search, search);
  yield takeEvery(actions.skip, skipAndList);
  yield takeEvery(actions.importFiles, importFiles);
  yield takeEvery(actions.generateProductionFile, generateProductionFile);
  yield takeEvery(actions.show, show);
  yield takeEvery(actions.create, create);
  yield takeEvery(actions.createOrderReview, createOrderReview);
  yield takeEvery(actions.update, update);
  yield takeEvery(actions.remove, remove);
  yield takeEvery(actions.unstoreItem, unstoreItem);
}
export default itemsSaga;
