/**
 * Actions and ActionTypes definitions for the ItemPart resource managment.
 * ActionTypes are the constants used to specify a specific action.
 * Actions are generated with redux-actions' createAction method.
 * Each action involving a fetch request also defines a SUCCESS and FAIL action called after the fetch result
 */
import { createAction } from 'redux-actions';
import notificationsActions from '../notifications'

/** LIST itemParts action-types */
const LIST = 'itemParts/LIST';
const LIST_SUCCESS = 'itemParts/LIST_SUCCESS';
const LIST_FAIL = 'itemParts/LIST_FAIL';

/** FILTER itemParts action-types */
const FILTER = 'itemParts/FILTER';
const FILTER_SUCCESS = 'itemParts/FILTER_SUCCESS';
const FILTER_FAIL = 'itemParts/FILTER_FAIL';

/** SHOW item action-types */
const SHOW = 'itemParts/SHOW';
const SHOW_SUCCESS = 'itemParts/SHOW_SUCCESS';
const SHOW_FAIL = 'itemParts/SHOW_FAIL';

/** CREATE item action-types */
const CREATE = 'itemParts/CREATE';
const CREATE_SUCCESS = 'itemParts/CREATE_SUCCESS';
const CREATE_FAIL = 'itemParts/CREATE_FAIL';

/** UPDATE item action-types */
// /!\ WE UPDATE THE ITEM IN SUCCESS because we return the item.
const UPDATE = 'itemParts/UPDATE';
const UPDATE_SKIP = 'itemParts/UPDATE_SKIP';
const UPDATE_SUCCESS = 'items/UPDATE_SUCCESS';
const UPDATE_FAIL = 'items/UPDATE_FAIL';

/** REMOVE item action-types */
const REMOVE = 'itemParts/REMOVE';
const REMOVE_SUCCESS = 'itemParts/REMOVE_SUCCESS';
const REMOVE_FAIL = 'itemParts/REMOVE_FAIL';

/** STORE item action-type */
const STORE = 'itemParts/STORE';
/** UNSTORE item action-type */
const UNSTORE = 'itemParts/UNSTORE';


/**
 * list itemParts
 */
const listSuccess = createAction(LIST_SUCCESS);
const listFail = createAction(LIST_FAIL);
const list = createAction(LIST);

/**
 * change item filters
 */
const filterSuccess = createAction(FILTER_SUCCESS);
const filterFail = createAction(FILTER_FAIL);
const filter = createAction(FILTER);

/**
 * show item
 */
const showSuccess = createAction(SHOW_SUCCESS);
const showFail = createAction(SHOW_FAIL);
const show = createAction(SHOW);

/**
 * create item
 */
const createSuccess = createAction(CREATE_SUCCESS);
const createFail = createAction(CREATE_FAIL);
const create = createAction(CREATE);

/**
 * update item
 */
const updateSuccess = createAction(UPDATE_SUCCESS);
const updateFail = createAction(UPDATE_FAIL);
const update = createAction(UPDATE);
const updateAndSkip = createAction(UPDATE_SKIP);

/**
 * remove item
 */
const removeSuccess = createAction(REMOVE_SUCCESS);
const removeFail = createAction(REMOVE_FAIL);
const remove = createAction(REMOVE);

/**
 * store item
 */
const storeItemPart = createAction(STORE,
  () => async (dispatch) => {
    if (navigator && 'storage' in navigator && 'estimate' in navigator.storage) {
      navigator.storage.estimate()
      .then(function(estimate){
        const {usage, quota} = estimate
        const percentUsed = Math.round(usage / quota * 100);
        const usageInMib = Math.round(usage / (1024 * 1024));
        const quotaInMib = Math.round(quota / (1024 * 1024));
        const message = `${usageInMib} out of ${quotaInMib} MiB used (${percentUsed}%)`;
        const color = percentUsed > 80 ? 'danger' : 'success'
        let alertOptions = { message, color, origin: 'storage', timeout: 5 }
        dispatch(notificationsActions.showAlert(alertOptions))
      });
    }
  }
);
/**
 * unstore item
 */
const unstoreItemPart = createAction(UNSTORE);

/** exports all actions as default */
export default {
  list: list,
  listSuccess: listSuccess,
  listFail: listFail,
  filter: filter,
  filterSuccess: filterSuccess,
  filterFail: filterFail,
  show: show,
  showSuccess: showSuccess,
  showFail: showFail,
  create: create,
  createSuccess: createSuccess,
  createFail: createFail,
  update: update,
  updateAndSkip: updateAndSkip,
  updateSuccess: updateSuccess,
  updateFail: updateFail,
  remove: remove,
  removeSuccess: removeSuccess,
  removeFail: removeFail,
  storeItemPart: storeItemPart,
  unstoreItemPart: unstoreItemPart
}
